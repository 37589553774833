import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import { Grid, Card, Divider } from "@material-ui/core";

import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import TablePagination from "@material-ui/core/TablePagination";
import { invokeApi, image_url } from "../../../bl_libs/ApiCalling";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import default_img from "../../../assets/images/default_img.jpg";
import { Menu, MenuItem } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
const moment = require("moment-timezone");

///-------dialog-transition-------///
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  hed: {
    fontWeight: "bold",
  },
  clmwdth: {
    width: "10px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnadd: {
    float: "right",
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  mnu: {
    cursor: "pointer",
  },
});

export default function Listappointment(props) {
  const classes = useStyles();
  const tableRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState();
  const [selectedrow, setrowdata] = React.useState([]);
  const [isuser, setusers] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [totalPage, settotalpage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("");
  const [search_found, setSearchFound] = React.useState(true);
  const [open_rescedule_app, setRescheduleApp] = React.useState(false);
  const [open_cancel_app, setCancelApp] = React.useState(false);
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  const [date3, setDate3] = useState(null);

  const [show_rescedule_slots, setshow_rescedule_slots] = useState(false);

  // error if date not set
  const [Error1, setError1] = useState(false);
  const [Error2, setError2] = useState(false);
  const [Error3, setError3] = useState(false);
  const [rescheduled_request_note, setrescheduled_request_note] = useState();
  const [rescheduled_request_date_time, setrescheduled_request_date_time] =
    useState();
  const [appointment_reschedule_id, setAppointmentResceduleID] = useState("");
  const [cancel_note, setCancelNote] = useState("");
  const [appointment_cancel_id, setAppointmentCancelID] = useState("");
  const [appointment_reject_reschedule, setAppointmentRejectReschedule] =
    useState("");
  const [reject_reschedule, setCancelReschedule] = useState(false);
  const [reason_text, setReasonText] = useState("");
  const [filter, setFilter] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8]);
  const [AcceptReschedule, setAcceptReschedule] = useState(false);
  const [AcceptReschedule_id, setAcceptReschedule_id] = useState("");
  const [AcceptReschedule_date, setAcceptReschedule_date] = useState();
  const [showdate, setshowdate] = useState([]);
  useEffect(() => {
    if (!open_rescedule_app) {
      setError1(false);
      setError2(false);
      setError3(false);
      setDate1(null);
      setDate2(null);
      setDate3(null);
    }
  }, [open_rescedule_app]);

  const [columns, setColumns] = React.useState([
    {
      title: <span className={classes.hed}>#</span>,
      width: 150,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={classes.hed}>Clinic Name</span>,
      field: "clinic_name",
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Patient Name</span>,
      field: "patient_name",
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Title</span>,
      render: (rowData) => <>{rowData.title.slice(0, 15)}</>,
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Delete By user</span>,
      render: (rowData) => (
        <>
          {rowData.delete_by_user == true ? (
            <span style={{ fontWeight: "bold" }}>True</span>
          ) : (
            <>False</>
          )}
        </>
      ),
      sorting: false,
    },

    {
      title: <span className={classes.hed}> Appointment Time</span>,
      render: (rowData) => (
        <>
          <div>
            {moment(rowData.appointment_date_time).format("DD-MM-YYYY hh:mma")}
          </div>
        </>
      ),
    },
    {
      title: <span className={classes.hed}> Appointment Status</span>,
      render: (rowData) => (
        <>
          <div>
            {rowData.appointment_status == 0 ? (
              <>Pending approval </>
            ) : rowData.appointment_status == 1 ? (
              <>Appointment Cancel by user</>
            ) : rowData.appointment_status == 2 ? (
              <>Reschedule Request </>
            ) : rowData.appointment_status == 3 ? (
              <>Reschedule Request accepted</>
            ) : rowData.appointment_status == 4 ? (
              <>Reschedule Request cancel</>
            ) : rowData.appointment_status == 5 ? (
              <>Appointment confirm</>
            ) : rowData.appointment_status == 6 ? (
              <>Reschedule appoitment</>
            ) : rowData.appointment_status == 7 ? (
              <>Appointment cancel by admin</>
            ) : rowData.appointment_status == 8 ? (
              <>Reschedule request sent</>
            ) : (
              <>N/A</>
            )}
          </div>
        </>
      ),
    },
    {
      title: <span className={classes.hed}> Status</span>,
      render: (rowData) => (
        <div style={{ width: 100 }}>
          {rowData.status === true ? (
            <>
              <Radio
                style={{ float: "left" }}
                checked={true}
                color="primary"
                name="Radio"
              />
              <p style={{ paddingTop: "10px" }}>Active</p>
            </>
          ) : (
            <>
              <Radio
                style={{ float: "left" }}
                checked={true}
                disabled
                color="primary"
                name="Radio"
              />
              <p style={{ paddingTop: "10px" }}>In Active</p>
            </>
          )}
        </div>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Action</span>,
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            <div className={classes.mnu}>
              <Menu
                key="left"
                direction="right"
                align="center"
                position="anchor"
                viewScroll="auto"
                menuButton={<MoreVertIcon></MoreVertIcon>}
              >
                {rowData.appointment_status == 2 && (
                  <MenuItem
                    onClick={() => {
                      console.log(rowData);
                      setAcceptReschedule(true);
                      setAcceptReschedule_id(rowData._id);
                      setAcceptReschedule_date(
                        rowData.rescheduled_request_date_time
                      );
                    }}
                    className={classes.mnu}
                  >
                    Accept
                  </MenuItem>
                )}
                ,
                <MenuItem
                  onClick={() => handleClickOpen(rowData)}
                  className={classes.mnu}
                >
                  Delete
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    props.history.push({
                      pathname: "/postit/edit-appointment",
                      appointment_data: rowData,
                    })
                  }
                  className={classes.mnu}
                >
                  Edit
                </MenuItem>
                {rowData.appointment_status != 1 &&
                  rowData.appointment_status != 7 && (
                    <MenuItem
                      onClick={() => {
                        openRescheduleDialog(rowData);
                      }}
                      className={classes.mnu}
                    >
                      Reschedule
                    </MenuItem>
                  )}
                ,
                {rowData.appointment_status == 2 ? (
                  <MenuItem
                    onClick={() => {
                      setAppointmentRejectReschedule(rowData._id);
                      setCancelReschedule(true);
                    }}
                    className={classes.mnu}
                  >
                    Reject Reschedule
                  </MenuItem>
                ) : null}
                ,
                {rowData.appointment_status == 8 && (
                  <MenuItem
                    onClick={() => {
                      handleopenslotsview(rowData);
                    }}
                    className={classes.mnu}
                  >
                    View resceduled slots
                  </MenuItem>
                )}
                {rowData.appointment_status != 1 &&
                  rowData.appointment_status != 7 && (
                    <MenuItem
                      onClick={() => {
                        setCancelApp(true);
                        setAppointmentCancelID(rowData._id);
                      }}
                      className={classes.mnu}
                    >
                      Cancel
                    </MenuItem>
                  )}
                ,
                <MenuItem
                  onClick={() => {
                    props.history.push({
                      pathname: `/postit/detail-appoitment/${rowData._id}`,
                    });
                  }}
                  className={classes.mnu}
                >
                  Detail
                </MenuItem>
              </Menu>
            </div>
          </div>
        </>
      ),
      sorting: false,
    },
  ]);

  const handleopenslotsview = (row) => {
    setshowdate(row.reschedule_date_time);
    setshow_rescedule_slots(true);
  };
  const rejectRescheduleAppointment = async () => {
    setCancelReschedule(false);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/appointment/reject_reschedule_request/${appointment_reject_reschedule}`,
      method: "POST",
      postData: { reason_text: reason_text },
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        enqueueSnackbar(res.message, { variant: "success" })
        get_appointment();
      }
      else {
        enqueueSnackbar(res.message, { variant: "error" })
      }
    });
    setReasonText("");
  };
  const handleDateChange = (date, index) => {
    {
      index == 1 && setDate1(new moment(date).format("YYYY-MM-DDTHH:mm"));
    }
    {
      index == 2 && setDate2(new moment(date).format("YYYY-MM-DDTHH:mm"));
    }
    {
      index == 3 && setDate3(new moment(date).format("YYYY-MM-DDTHH:mm"));
    }
  };
  const Addappointment = () => {
    props.history.push("/postit/add-appointment");
  };
  const handleClickOpen = (rowdata) => {
    setrowdata(rowdata);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setData([]);
    setPage(newPage);
  };

  const cancelAppointment = async () => {
    setCancelApp(false);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/appointment/cancel_appointment/${appointment_cancel_id}`,
      method: "POST",
      postData: { cancel_note: cancel_note },
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        get_appointment();
        enqueueSnackbar(res.message, { variant: "success" })
      }
      else {
        enqueueSnackbar(res.message, { variant: "error" })
      }
    });
    setCancelNote("");
  };

  const reschduleAppointment = async () => {
    console.log(date1);
    if (date1 == null || date2 == null || date3 == null) {
      if (date1 == null) {
        setError1(true);
      }
      if (date2 == null) {
        setError2(true);
      }
      if (date3 == null) {
        setError3(true);
      }
      return;
    }
    setRescheduleApp(false);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/appointment/send_reschedule_slots/${appointment_reschedule_id}`,
      method: "POST",
      postData: { reschedule_date_time: [date1, date2, date3] },
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        get_appointment();
        enqueueSnackbar(res.message, { variant: "success" })
      }
      else {
        enqueueSnackbar(res.message, { variant: "error" })
      }
    });
  };

  const handleAccept = async () => {
    setAcceptReschedule(false);
    console.log(typeof newtime);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/appointment/accept_reschedule_request/${AcceptReschedule_id}`,
      method: "POST",
      postData: { appointment_date_time: AcceptReschedule_date },
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        get_appointment();
        enqueueSnackbar(res.message, { variant: "success" })
      }
      else {
        enqueueSnackbar(res.message, { variant: "error" })
      }
    });
  };

  const openRescheduleDialog = async (rowData) => {
    setRescheduleApp(true);
    console.log(rowData, "rowData");
    setrescheduled_request_note(rowData.reschedule_note);

    if (rowData.rescheduled_request_date_time != "") {
      setrescheduled_request_date_time(rowData.rescheduled_request_date_time);
    }
    setAppointmentResceduleID(rowData._id);
  };

  const hanldeSearchChange = (e) => {
    setSearch(e.target.value);
    setData([]);
    setSearchFound(true);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    const data = {
      data: e.target.value,
    };

    let requestObj = {
      path: `/api/appointment/search_appointment`,
      method: "POST",
      headers: header,
      postData: data,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setData(res.appointment);
        settotalpage(1);
        if (res.appointment.length == 0) {
          setSearchFound(false);
        } else {
          setSearchFound(true);
        }
      }
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setData([]);
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };
  const deleteAppointment = () => {
    setOpen(false);
    setusers(false);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    console.log(selectedrow, "row");
    let requestObj = {
      path: `/api/appointment/delete_appointment/${selectedrow._id}`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setusers(true)
        if (search == "") {
          get_appointment(page);
          enqueueSnackbar(res.message, { variant: "success" })
        } else {
          hanldeSearchChange({ target: { value: search } });
          enqueueSnackbar(res.message, { variant: "error" })
        }
      }
      else {
        setusers(true)
      }
    });
  };
  const get_appointment = async (_page) => {
    setusers(false);
    setFilter([0, 1, 2, 3, 4, 5, 6, 7, 8]);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    console.log(_page, "page");
    let requestObj = {
      path: "/api/appointment/list_appointment",
      method: "GET",
      queryParams: { page: _page, limit: rowsPerPage },
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE list appointments");
      if (res.code == 200) {
        setData(res.appointment.appointments);
        settotalpage(res.appointment.total_pages);
        setusers(true);
      }
      else {
        setusers(true)
      }
    });
  };
  const getfilterAppointment = async (_page) => {
    setusers(false);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/appointment/filter_apppointment",
      method: "POST",
      queryParams: { page: _page, limit: rowsPerPage },
      headers: header,
      postData: { filter: filter },
    };
    console.log(requestObj, "bodybody");
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        console.log(res);
        setData(res.appointment.appointment);
        settotalpage(res.appointment.total_pages);
        setusers(true);
      }
      else {
        setusers(true)
      }
    });
  };

  useEffect(() => {
    if (search == "") {
      get_appointment(page);
    } else {
      hanldeSearchChange({ target: { value: search } });
    }
  }, [rowsPerPage, page, search]);
  useEffect(async () => {
    if (filter !== -1) {
      await getfilterAppointment(page);
    } else {
      await get_appointment(page);
    }
  }, [filter]);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <>

      <MuiThemeProvider>
        <Button
          variant={
            filter[0] == 0 && filter.length > 2 ? "contained" : "outlined"
          }
          color="primary"
          style={{ marginLeft: 5, marginBottom: 5 }}
          onClick={() => setFilter([0, 1, 2, 3, 4, 5, 6, 7, 8])}
        >
          All
        </Button>
        <Button
          variant={
            filter[0] == 0 && filter[1] == 8 ? "contained" : "outlined"
          }
          color="primary"
          style={{ marginLeft: 5, marginBottom: 5 }}
          onClick={() => setFilter([0, 8])}
        >
          Pending from patient
        </Button>
        <Button
          variant={
            filter[0] == 1 && filter[1] == 7 ? "contained" : "outlined"
          }
          color="primary"
          style={{ marginLeft: 5, marginBottom: 5 }}
          onClick={() => setFilter([1, 7])}
        >
          Cancel
        </Button>
        <Button
          variant={filter[0] == 2 ? "contained" : "outlined"}
          color="primary"
          style={{ marginLeft: 5, marginBottom: 5 }}
          onClick={() => setFilter([2])}
        >
          Reschedule Request
        </Button>
        {/* <Button
            variant={filter == 8 ? "contained" : "outlined"}
            color="primary"
            style={{ marginLeft: 5, marginBottom: 5 }}
            onClick={() => setFilter(8)}
          >
            Pending Confirmation
          </Button> */}
        <Button
          variant={filter[0] == 4 ? "contained" : "outlined"}
          color="primary"
          style={{ marginLeft: 5, marginBottom: 5 }}
          onClick={() => setFilter([4])}
        >
          Rejected Request
        </Button>
        <Button
          variant={
            filter[0] == 3 && filter[1] == 5 ? "contained" : "outlined"
          }
          color="primary"
          style={{ marginLeft: 5, marginBottom: 5 }}
          onClick={() => setFilter([3, 5])}
        >
          confirmed
        </Button>
        <Button
          onClick={Addappointment}
          className={classes.btnadd}
          variant="contained"
          color="primary"
        >
          Add Appointment
        </Button>
        {isuser == false ? (
          <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
        ) : (<MaterialTable
          tableRef={tableRef}
          style={{ marginTop: "50px" }}
          title="Appointments"
          icons={tableIcons}
          columns={columns.map((c) => ({ ...c, tableData: undefined }))}
          components={{
            Pagination: (props) => (
              <TablePagination
                component="div"
                count={totalPage}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPageOptions={[25, 50, 100]}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            ),
            //   Toolbar: (props) => (
            //     <div>
            //       <Box display="flex" p={1}>
            //         <Box p={1} flexGrow={1}>
            //           <h3>Appointments</h3>
            //         </Box>
            //         <Box p={1}>
            //           <TextField
            //             id="input-with-icon-textfield"
            //             value={search}
            //             focused
            //             autoFocus={true}
            //             onChange={hanldeSearchChange}
            //             placeholder="Search"
            //             InputProps={{
            //               startAdornment: (
            //                 <InputAdornment
            //                   position="start"
            //                   style={{ float: "right" }}
            //                 >
            //                   <SearchIcon />
            //                 </InputAdornment>
            //               ),
            //             }}
            //           />
            //         </Box>
            //       </Box>
            //     </div>
            //   ),
          }}
          // localization={{
          //   body: {
          //     emptyDataSourceMessage: (
          //       <div style={{ padding: 20 }}>
          //         {search_found == false ? (
          //           <h5>No data found to display</h5>
          //         ) : (
          //           <CircularProgress />
          //         )}
          //       </div>
          //     ),
          //   },
          // }}
          data={data}
          options={{
            actionsColumnIndex: -1,
            search: true,
            pageSize: rowsPerPage,
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: "#DAD651",
              color: "white",
              fontWeight: "bold",
            },
          }}
        />
        )}
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete this Appointment ?`}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Disagree
            </Button>
            <Button onClick={deleteAppointment} color="primary">
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        {/* Rescedule Appointment */}

        <div>
          <Dialog
            open={open_rescedule_app}
            onClose={() => setRescheduleApp(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent
              style={{
                display: "flex",
                flexDirection: "column",
                width: "500px",
              }}
            >
              <Grid container>
                <Grid>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    component="b"
                    style={{ alignItems: "'center'" }}
                  >
                    <b>Reschedule Appointment</b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid style={{ marginTop: 10 }}>
                <Grid>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    component="b"
                    style={{}}
                  >
                    <b>Note :</b>
                  </Typography>
                </Grid>
                <Grid>{rescheduled_request_note}</Grid>
              </Grid>

              <Grid style={{ paddingTop: 10 }} container>
                <Grid>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    component="b"
                    style={{}}
                  >
                    <b>Suggested time from patient :</b>
                  </Typography>
                </Grid>
                <Grid style={{ paddingLeft: 10, paddingTop: 5 }}>
                  {rescheduled_request_date_time != ""
                    ? moment(rescheduled_request_date_time).format(
                      "DD/MM/YYYY  hh:mm a"
                    )
                    : "N/A"}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  disablePast
                  error={Error1}
                  margin="normal"
                  id="date-picker-dialog"
                  label="Slot 1"
                  format="dd/MM/yyyy hh:mm a"
                  inputVariant="outlined"
                  value={date1}
                  onChange={(e) => {
                    setError1(false);
                    handleDateChange(e, 1);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  disablePast
                  error={Error2}
                  margin="normal"
                  id="date-picker-dialog"
                  label="Slot 2"
                  format="dd/MM/yyyy hh:mm a"
                  inputVariant="outlined"
                  value={date2}
                  onChange={(e) => {
                    setError2(false);
                    handleDateChange(e, 2);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  disablePast
                  error={Error3}
                  margin="normal"
                  id="date-picker-dialog"
                  label="Slot 3"
                  format="dd/MM/yyyy hh:mm a"
                  inputVariant="outlined"
                  value={date3}
                  onChange={(e) => {
                    setError3(false);
                    handleDateChange(e, 3);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setRescheduleApp(false)}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={() => reschduleAppointment()}
                color="primary"
                variant="outlined"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {/* show resceduled time slots */}

        <div>
          <Dialog
            open={show_rescedule_slots}
            onClose={() => setshow_rescedule_slots(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent
              style={{
                display: "flex",
                flexDirection: "column",
                width: "500px",
              }}
            >
              <Grid container>
                <Grid>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    component="b"
                    style={{ alignItems: "'center'" }}
                  >
                    <b>Reschedule Appointment Slots</b>
                  </Typography>
                </Grid>
              </Grid>

              <Grid style={{ paddingTop: 10 }} container>
                <Grid>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    component="b"
                    style={{}}
                  >
                    <b>Slot 1 :</b>
                  </Typography>
                </Grid>
                <Grid style={{ paddingLeft: 10, paddingTop: 5 }}>
                  {showdate[0]
                    ? moment(showdate[0]).format("DD/MM/YYYY  hh:mm a")
                    : "N/A"}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
              </Grid>

              <Grid style={{ paddingTop: 10 }} container>
                <Grid>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    component="b"
                    style={{}}
                  >
                    <b>Slot 2 :</b>
                  </Typography>
                </Grid>
                <Grid style={{ paddingLeft: 10, paddingTop: 5 }}>
                  {showdate[1]
                    ? moment(showdate[1]).format("DD/MM/YYYY  hh:mm a")
                    : "N/A"}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
              </Grid>

              <Grid style={{ paddingTop: 10 }} container>
                <Grid>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    component="b"
                    style={{}}
                  >
                    <b>Slot 3 :</b>
                  </Typography>
                </Grid>
                <Grid style={{ paddingLeft: 10, paddingTop: 5 }}>
                  {showdate[2]
                    ? moment(showdate[2]).format("DD/MM/YYYY  hh:mm a")
                    : "N/A"}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setshow_rescedule_slots(false)}
                color="primary"
                variant="outlined"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Dialog
          open={open_cancel_app}
          onClose={() => setCancelApp(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ width: "500px" }} id="alert-dialog-title">
            {"Cancel appointment note."}
          </DialogTitle>
          <TextField
            multiline
            style={{ padding: 10 }}
            rows={4}
            variant="outlined"
            value={cancel_note}
            fullWidth
            id="des"
            onChange={(e) => setCancelNote(e.target.value)}
            label="Note"
          />
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCancelApp(false)} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => cancelAppointment()}
              color="primary"
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={reject_reschedule}
          onClose={() => setCancelReschedule(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ width: "500px" }} id="alert-dialog-title">
            {"Are you sure you want to reject reschedule appointment?"}
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                multiline
                style={{ margin: 0 }}
                rows={4}
                variant="outlined"
                value={reason_text}
                fullWidth
                id="Reason Note"
                onChange={(e) => setReasonText(e.target.value)}
                label="Reason Note"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setCancelReschedule(false)}
              color="primary"
            >
              Disagree
            </Button>
            <Button
              onClick={() => rejectRescheduleAppointment()}
              color="primary"
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={AcceptReschedule}
          onClose={() => setAcceptReschedule(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ width: "500px" }} id="alert-dialog-title">
            {`Are you sure you want to Accept reschedule appointment on ${moment(
              AcceptReschedule_date
            ).format("DD-MM-YYYY hh:mm a")}?`}
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setAcceptReschedule(false)}
              color="primary"
            >
              Disagree
            </Button>
            <Button onClick={() => handleAccept()} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>

    </>
  );
}
