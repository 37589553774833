import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { invokeApi, image_url } from "../../../../bl_libs/ApiCalling";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CKEditor from "./editor/CKEditor";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 600, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    height: 55,
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  uplaod: {
    marginTop: 20,
  },
  blog_img: {
    marginTop: 4,
    height: "90%",
  },
  formControl: {
    marginTop: 10,
  },
  backarrow: {
    marginInlineStart: "-100px",
    cursor: "pointer",
    marginTop: "2%",
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
  input: {
    marginLeft: "40%",
  },
}));

export default function EditPhoto(props) {
  const classes = useStyles();
  const history = useHistory();
  const [photo_data, setPhotoData] = React.useState(props?.location?.photo_data);
  const [photo_length, setPhotoLength] = React.useState(
    props.location.photo_data.photos.length
  );
  const [is_data, setData] = React.useState(false);
  const [deault_tags, setDefaultTag] = React.useState(photo_data?.tags);
  const [status, setStatus] = React.useState(photo_data?.status);
  const [default_user, setDefaultUsers] = React.useState([]);
  const [selected_user, setSelectedCustomer] = React.useState(
    photo_data.user_info
  );
  const [short_description, setShortDescription] = React.useState(
    photo_data.description
  );
  const [Savepublish, setSavePublish] = React.useState(
    photo_data.send_notification
  );
  const [Image, setImage] = React.useState(photo_data.image);
  const [file, setPhoto] = React.useState(photo_data.image);
  const [tags, setTags] = React.useState([{ title: "post" }]);
  const [final_tags, setFinalTags] = React.useState(photo_data.tags);
  const [errorflag, setflag] = React.useState("");
  const [name, set_name] = React.useState([]);
  const [patients_tags, setpatients_tags] = React.useState(
    photo_data.patients_tags
  );
  const [photo_array, setPhotosArray] = React.useState(photo_data.photos);
  const [is_photo_uplaod, setIsPhotoUplaod] = React.useState(true);
  const [isdiabled, setisdiabled] = React.useState(false);

  const handleback = () => {
    history.goBack();
  };
  const handleChangeInCkEdditor = (text) => {
    setShortDescription(text);
  };

  const handleSavePublishChange = (event) => {
    setSavePublish(event.target.value);
    console.log(event.target.value, "save or publish");
  };

  const handleImageRemove = (index) => {
    console.log(index, "INDEX");
    let images_array = [];
    photo_array.map((x, i) => {
      if (i != index) {
        images_array.push(x);
      }
    });
    setPhotosArray(images_array);
  };

  const update_image = (event, i) => {
    setData(false)
    console.log(i);
    let formData = new FormData();
    console.log("Image upload");
    formData.append("image", event.target.files[0]);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/app_api/upload_CKimage",
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE EDIT IMAGE");
      if (res.code == 200) {
        setTimeout(() => {
          const new_images = [...photo_array];
          new_images[i] = { "image_path": res.image };
          setPhotosArray(new_images);
          setData(true)
        }, 1000);
        //setPhotosArray([...photo_array, res.image]);
      }
      else{
        setData(true)
      }
    });
  };

  const fileChangedHandler = (event) => {
    setPhoto(event.target.files[0]);

    setData(false)
    let formData = new FormData();
    console.log("Image upload");
    formData.append("image", event.target.files[0]);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/app_api/upload_CKimage",
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE okokokook");
      if (res.code == 200) {
        setTimeout(() => {
          setPhotosArray([...photo_array, { "image_path": res.image }]);
          //  setPhotosArray([res.image]);
          setPhoto(res.image);
          setData(true)
        }, 1000);
      }
      else {
        setData(true)
      }
    });
  };
  const handle_tag_change = (value) => {
    console.log(value, "value");
    let _tags = [];
    value.map((x, i) => {
      _tags.push({ title: x });
    });
    setFinalTags(_tags);
    console.log(_tags, "final tags");
  };
  const handle_patiett_tag_change = (value) => {
    console.log(value, "value");
    let _patTag = [];
    value.map((x, i) => {
      _patTag.push({ name: x.name, user_id: x.user_id });
    });
    setpatients_tags(_patTag);
    console.log(_patTag, "final tags");
  };

  const Addphoto = (event) => {
    event.preventDefault();
    if (photo_array.length == 0) {
      setflag("Please uplaod photo");
      return;
    }
    let formData = new FormData();
    let user; //formdata object
    if (selected_user._id) {
      user = selected_user._id;
    } else {
      user = selected_user.user_id;
    }
    formData.append("status", status);
    var filtered = final_tags.filter(function (el) {
      return el.title != null && el.title !== "" && el.title.trim() !== "";
    });
    formData.append("tags", JSON.stringify(filtered));
    formData.append("description", short_description);
    var Patient_filtered = patients_tags.filter(function (el) {
      return el.name != null && el.name !== "" && el.name.trim() !== "";
    });
    formData.append("patients_tags", JSON.stringify(Patient_filtered));
    formData.append("save_or_publish", Savepublish);
    formData.append("publish_date_time", Date().toLocaleString());
    formData.append("order", photo_data.order);
    console.log(photo_array, "photo_array");
    console.log(photo_data.order, "photo_array order");
    const data = {
      user_id: user,
      status: status,
      tags: filtered,
      patients_tags: Patient_filtered,
      description: short_description,
      save_or_publish: Savepublish,
      publish_date_time: Date().toLocaleString(),
      order: photo_data.order,
      photos: photo_array.reverse(),
    };

    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/photo/edit_photo/${photo_data._id}`,
      method: "POST",
      postData: data,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        props.history.push("/postit/photos");
      } else {
        setflag(res.message);
      }
    });
  };
  const get_tags = () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/tags/all_tags`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setTags(res.tags);
        //setFinalTags(res.tags);
      }
    });
  };
  const getDefaultUser = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/customer/default_customer`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        const default_user = [];
        res.customer.map((x, i) => {
          if (x.status == "true" || x.status == true) {
            default_user.push(x);
          }
        });
        console.log(default_user, "default_user");
        setDefaultUsers(default_user);
        setData(true);
      }
    });
  };
  const get_users = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/customer/list_all_customers",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        let patients = [];
        let customer = res.customer;
        customer.map((x, i) => {
          if (x.is_patient == true) {
            patients.push(x);
          }
        });
        set_name(patients);
        setpatients_tags(photo_data.patients_tags);
      }
    });
  };
  const get_initial_data = async () => {
    await getDefaultUser();
    await get_tags();
    await get_users();
  };

  useEffect(() => {
    get_initial_data();
  }, []);
  if (is_photo_uplaod == false) {
    return <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />;
  }

  return (
    <>
      {is_data == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="xs">
          <ArrowBackIcon
            className={classes.backarrow}
            onClick={handleback}
          ></ArrowBackIcon>
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Edit photo
            </Typography>
            <form className={classes.form} onSubmit={Addphoto}>
              {errorflag && (
                <Alert severity="error" style={{ marginBottom: 10 }}>
                  {errorflag}
                </Alert>
              )}
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Autocomplete
                    id="users"
                    disableClearable
                    value={selected_user}
                    onChange={(event, value) => {
                      setSelectedCustomer(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    options={default_user}
                    className={classes.patient}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose user"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl component="fieldset" style={{ marginTop: 10 }}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="primary"
                          checked={status}
                          onClick={() => setStatus(!status)}
                        />
                      }
                      label="Status"
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Autocomplete
                multiple
                id="tags-filled"
                options={name}
                value={patients_tags}
                getOptionLabel={(name) => name.name}
                getOptionSelected={(option, value) =>
                  option.user_id === value.user_id
                }
                onChange={(event, value) => handle_patiett_tag_change(value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <>
                      <Chip
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    </>
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    multiline
                    style={{ marginTop: 20 }}
                    rows={1}
                    rowsMax={4}
                    label="Patients"
                    placeholder="Enter new Patient"
                  />
                )}
              />

              <div style={{ marginTop: 20 }}>
                <CKEditor
                  content={short_description}
                  setContent={handleChangeInCkEdditor}
                />
              </div>
              {/* {file ? (
                <img
                  key={Math.random()}
                  alt={"Photo image"}
                  src={image_url + file}
                  style={{ width: 600 }}
                ></img>
              ) : image_url !== "" ? (
                <img src={image_url + Image} className={classes.blog_img}></img>
              ) : null} */}

              <Box
                display="flex"
                style={{ "overflowY": "scroll" }}
                flexDirection="row"
                p={1}
                m={1}
              >
                {console.log(photo_array, "popoppopo")}
                {photo_array.map((x, i) => {
                  return (
                    <Box
                      p={1}
                      key={i}
                      style={{
                        Width: 500,
                        height: 400,
                        cursor: "pointer",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        key={Math.random()}
                        variant="contained"
                        component="label"
                        style={{
                          marginBottom: -100,
                          marginLeft: 20,
                          backgroundColor: "rgb(0 0 0 / 10%)",
                          boxShadow: "0px 0px 0px 0px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/x-png,image/jpeg"
                          hidden
                          onChange={(e) => update_image(e, i)}
                        ></input>

                        <EditIcon key={Math.random()}></EditIcon>
                      </Button>
                      <Button
                        key={Math.random()}
                        variant="contained"
                        component="label"
                        style={{
                          marginBottom: -100,
                          marginLeft: 5,
                          backgroundColor: "rgb(0 0 0 / 10%)",
                          boxShadow: "0px 0px 0px 0px",
                        }}
                        onClick={() => handleImageRemove(i)}
                      >
                        <ClearOutlinedIcon
                          key={Math.random()}
                        ></ClearOutlinedIcon>
                      </Button>
                      <img
                        src={image_url + x.image_path}
                        className={classes.blog_img}
                        alt=""
                      />
                    </Box>
                  );
                })}
              </Box>
              <Button
                fullWidth
                variant="outlined"
                component="label"
                className={classes.uplaod}
                startIcon={<CloudUploadIcon />}
                color="primary"
              >
                {photo_array.length < 4 ? (
                  <input
                    type="file"
                    accept="image/x-png,image/jpeg"
                    hidden
                    disabled={isdiabled}
                    onChange={fileChangedHandler}
                  ></input>
                ) : (
                  <input
                    type="file"
                    accept="image/x-png,image/jpeg"
                    hidden
                    disabled
                    onChange={fileChangedHandler}
                  ></input>
                )}
                Upload image (600X500)
              </Button>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Save or Publish
                    </InputLabel>
                    {console.log(Savepublish,"oooooooooooo")}
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={Savepublish}
                      fullWidth
                      onChange={handleSavePublishChange}
                      label="Save or Publish"
                      disabled={Savepublish}
                    >
                      <MenuItem value={false}>Save</MenuItem>
                      <MenuItem value={true}>Publish</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Edit photo
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
