import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import { invokeApi, image_url } from "../../../bl_libs/ApiCalling";
import { useSnackbar } from "notistack";
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
  },
  alert: {
    width: "100%",
  },
}));
export default function Add_patient(props) {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar()
  const [clinic, setClinic] = useState([]);
  const [selected_clinic, setSelectedClinic] = useState({});
  const [users, setUsers] = React.useState([]);
  const [selected_user, setSelectedUser] = React.useState({});
  const [errorflag, setflag] = React.useState("");

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = (event) => {
    event.preventDefault();
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    console.log(selected_user, "selected user");
    const data = {
      clinic_id: selected_clinic._id,
      customer_id: selected_user._id,
      status: true,
    };
    let requestObj = {
      path: `/api/patient/add_patient`,
      method: "POST",
      headers: header,
      postData: data,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE make patient");
      if (res.code == 200) {
        history.goBack();
        enqueueSnackbar(res.message, { variant: "success" })
      } else {
        setflag(res.message);
        enqueueSnackbar(res.message, { variant: "error" })
      }
    });
  };
  const get_users = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/customer/list_all_customers",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        let final_users = [];
        let customer = res.customer;
        customer.map((x, i) => {
          if (x.is_patient !== true) {
            final_users.push(x);
          }
        });
        setUsers(final_users);
      }
    });
  };
  const get_clinins = () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/clinic/all_clinic",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      setClinic(res.clinic);
      console.log(res, "Response");
    });
  };
  useEffect(() => {
    get_users();
    get_clinins();
  }, []);
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            marginLeft: "-550px",
          }}
          onClick={handleback}
        />
        <Typography className={classes.txt} component="h1" variant="h5">
          Add Patient
        </Typography>
        {errorflag && (
          <Alert className={classes.alert} severity="error">
            {errorflag}
          </Alert>
        )}
        <form className={classes.form} onSubmit={handlesubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                id="patients"
                disableClearable
                onChange={(event, value) => setSelectedUser(value)}
                getOptionLabel={(option) => option.name}
                options={users}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Users"
                    margin="normal"
                    variant="outlined"
                    required
                    InputProps={{ ...params.InputProps, type: "search" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="patients"
                disableClearable
                value={selected_clinic}
                onChange={(event, value) => {
                  setSelectedClinic(value);
                }}
                getOptionLabel={(option) => option.clinic_name}
                options={clinic}
                className={classes.patient}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Clinics"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: "search" }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Add patient
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
