import React,{useEffect,useState,useRef} from "react";
import PropTypes from "prop-types";
import Appbar from "./appbar/appbar";


const Minimal = (props) => {
  const { children } = props;

  
  return (
    <div className="App">
      <div className="App-header">
        <Appbar >{children}</Appbar>
      </div>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Minimal;
