import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { invokeApi, image_url } from "../../../../bl_libs/ApiCalling";
///-------dialog-transition-------///
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "primary",
  },
  backarrow: {
    cursor: "pointer",
    marginBottom: 20,
  },
  carContent: { maxHeight: 300, overflowY: "auto" },
  del: { marginTop: 5, cursor: "pointer" },
}));

function VideoComments(props) {
  //console.log(props.location.video_data, "photo data");
  const classes = useStyles();
  const history = useHistory();
  const [video_data, setVideoData] = React.useState();
  const [is_data, setData] = React.useState(false);
  const [comments, setComemnts] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [selectedrow, setrowdata] = React.useState([]);
  const [comment_index, setCommentIndex] = React.useState();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = (rowdata, index) => {
    console.log(rowdata, "comment data");
    setCommentIndex(index);
    setrowdata(rowdata);
    setOpen(true);
  };
  const deleteComment = () => {
    setOpen(false);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    //setVideoData(video_data.comments.pop(selectedrow));
    const post_data = {
      comment_id: selectedrow._id,
    };
    let requestObj = {
      path: `/api/comment/delete_comment_admin`,
      method: "POST",
      postData: post_data,
      headers: header,
    };
    video_data.comments.splice(comment_index, 1);
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        getPhotoDetail();
      }
    });
  };
  const getPhotoDetail = () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };

    let requestObj = {
      path: `/api/video/detail_video/${props.match.params.videoid}`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setVideoData(res.video);
        setComemnts(res.video.comments);
        setData(true);
      }
    });
  };
  useEffect(() => {
    getPhotoDetail();
  }, []);
  return (
    <>
      {is_data == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container maxWidth="sm">
          <ArrowBackIcon
            className={classes.backarrow}
            onClick={() => history.goBack()}
          ></ArrowBackIcon>
          <Card className={classes.root}>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  src={image_url + video_data.user_info.profile_image}
                  className={classes.avatar}
                ></Avatar>
              }
              //   action={
              //     <IconButton aria-label="settings">
              //       <MoreVertIcon />
              //     </IconButton>
              //   }
              title={video_data.user_info.name}
              //   subheader="September 14, 2016"
            />
            <CardMedia
              className={classes.media}
              image={image_url + video_data.image}
              title="Photo Image"
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                {video_data.description}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <Typography color="textSecondary" variant="h5">
                Comments
              </Typography>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent className={classes.carContent}>
                {comments.map((x, i) => {
                  return (
                    <Paper>
                      <div
                        style={{
                          padding: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={1}>
                            <Avatar
                              aria-label="recipe"
                              src={image_url + x.user.image}
                              className={classes.avatar}
                            ></Avatar>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography paragraph style={{ marginTop: 10 }}>
                              {x.user.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <DeleteIcon
                              color="primary"
                              className={classes.del}
                              onClick={() => handleClickOpen(x, i)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{ marginLeft: 10 }}
                            >
                              {x.comment}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  );
                })}
              </CardContent>
            </Collapse>
          </Card>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to delete this comment?`}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={deleteComment} color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      )}
    </>
  );
}
export default withRouter(VideoComments);
