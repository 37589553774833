import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CKEditor from "./editor/CKEditor";
import Alert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import { title } from "../../../../assets/jss/material-dashboard-react";
import { invokeApi, image_url } from "../../../../bl_libs/ApiCalling";
import Avatar from "@material-ui/core/Avatar";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 600, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  uplaod: {
    marginTop: 20,
  },
  blog_img: {
    marginTop: 10,
    width: 600,
    height: 300,
  },
  formControl: {
    marginTop: 10,
  },
  backarrow: {
    marginInlineStart: "-100px",
    cursor: "pointer",
    marginTop: "2%",
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
}));

export default function Add_podcast_category(props) {
  const classes = useStyles();
  const history = useHistory();
  const [cat_date, set_cat] = React.useState(props.location.podcast_data);
  const [text, settext] = React.useState(cat_date.detailed_description);
  const [blog_title, setblog_title] = React.useState(cat_date.title);
  const [blog_description, setblog_des] = React.useState(
    cat_date.short_description
  );
  const [profile_image, setdefaultimage] = React.useState(cat_date.icon);
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [status, setStatus] = React.useState(cat_date.status);

  const [errorflag, setflag] = React.useState("");

  const handleback = () => {
    history.goBack();
  };
  const handleblog_title = (event) => {
    setblog_title(
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
    );
    console.log(blog_title);
  };
  const handleblog_des = (event) => {
    setblog_des(
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
    );
    console.log(blog_description);
  };
  const fileChangedHandler = (e) => {
    if (e.target.files.length) {
      setimage(e.target.files[0]);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleChangeInCkEdditor = (text) => {
    settext(text);
  };
  const handle_add_category = () => {
    console.log(title, "tititi");
    let formData = new FormData(); //formdata object
    if (Image !== "") {
      formData.append("image", Image);
    }
    formData.append("status", status);
    formData.append("title", blog_title);
    formData.append("short_description", blog_description);
    formData.append("detailed_description", text);
    // const Data = {
    //   status: status,
    //   title: blog_title,
    //   short_description: blog_description,
    //   detailed_description: text,
    // };
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/podcast_category/edit_podcast_category/${cat_date._id}`,
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        props.history.push("/postit/podcast-category");
      } else {
        setflag(res.message);
      }
    });
  };
  return (
    <Container component="main" maxWidth="xs">
      <ArrowBackIcon
        className={classes.backarrow}
        onClick={handleback}
      ></ArrowBackIcon>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Edit podcast category
        </Typography>
        <form className={classes.form}>
          {errorflag && <Alert severity="error">{errorflag}</Alert>}
          <Grid container spacing={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <input
                accept="image/x-png,image/jpeg"
                hidden
                id="contained-button-file"
                multiple
                type="file"
                onChange={fileChangedHandler}
              />
              <label htmlFor="contained-button-file">
                {file === "" ? (
                  <Avatar
                    alt="Remy Sharp"
                    className={classes.avtr}
                    src={image_url + profile_image}
                  >
                    <AddAPhotoIcon />
                  </Avatar>
                ) : (
                  <Avatar alt="Remy Sharp" className={classes.avtr} src={file}>
                    <AddAPhotoIcon />
                  </Avatar>
                )}
                <span style={{ marginLeft: 8 }}>200 X 200</span>
              </label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Podcast category title"
                name="title"
                onChange={handleblog_title}
                value={blog_title}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl component="fieldset" style={{ marginTop: 25 }}>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={status}
                      onClick={() => setStatus(!status)}
                    />
                  }
                  label="Status"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="normal"
            multiline
            rows={2}
            rowsMax={4}
            fullWidth
            onChange={handleblog_des}
            label="Podcast category Description"
            name="title"
            value={blog_description}
          />

          <CKEditor content={text} setContent={handleChangeInCkEdditor} />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handle_add_category}
          >
            Edit podcast category
          </Button>
        </form>
      </div>
    </Container>
  );
}
