import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Avatar from "@material-ui/core/Avatar";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import moment from "moment";
import { invokeApi, image_url } from "../../../../bl_libs/ApiCalling";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 600, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  uplaod: {
    marginTop: 20,
  },
  blog_img: {
    marginTop: 10,
    width: 600,
    height: 300,
  },
  formControl: {
    marginTop: 10,
  },
  backarrow: {
    marginInlineStart: "-100px",
    cursor: "pointer",
    marginTop: "2%",
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
  input: {
    marginLeft: "40%",
  },
}));

export default function Editstory(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setisOpen] = React.useState(false);
  const [istagOpen, setTagOpen] = React.useState(false);
  const [story_data, set_stroy_data] = React.useState();
  const [Image, setStroryImage] = React.useState("");
  const [status, setStatus] = React.useState(false);
  const [short_description, setShortDescription] = React.useState("");
  const [selected_user, setSelectedCustomer] = React.useState("");
  const [default_user, setDefaultUsers] = React.useState([]);
  const [file, setPhoto] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [final_tags, setFinalTags] = React.useState([]);
  const [errorflag, setflag] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expiry, setexpiry] = React.useState(String());
  const [name, set_name] = React.useState([]);
  const [patients_tags, setpatients_tags] = React.useState([]);
  const [laoding, setLaoding] = React.useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (expiry_hours) => {
    setAnchorEl(null);
    setexpiry(expiry_hours);
  };
  const handleback = () => {
    history.goBack();
  };

  const fileChangedHandler = (event) => {
    setPhoto(event.target.files[0]);
  };
  const handle_patiett_tag_change = (value) => {
    console.log(value, "value");
    let _patTag = [];
    value.map((x, i) => {
      _patTag.push({ name: x.name, user_id: x.user_id });
    });
    setpatients_tags(_patTag);
    console.log(_patTag, "final tags");
  };
  const handle_tag_change = (value) => {
    console.log(value, "value");
    let _tags = [];
    value.map((x, i) => {
      _tags.push({ title: x });
    });
    setFinalTags(_tags);
    console.log(_tags, "final tags");
  };

  const AddStory = (event) => {
    event.preventDefault();
    let formData = new FormData(); //formdata object
    //console.log(default_user, "default user");
    console.log(selected_user, "selected_user");
    if (selected_user._id) {
      formData.append("user_id", selected_user._id);
    } else {
      formData.append("user_id", selected_user.user_id);
    }
    formData.append("status", status);
    console.log(final_tags, "final tags");
    var filtered = final_tags.filter(function (el) {
      return el.title != null && el.title !== "" && el.title.trim() !== "";
    });
    console.log(filtered, "final tags");
    formData.append("tags", JSON.stringify(filtered));
    formData.append("description", short_description);
    formData.append("expiry_hours", parseInt(expiry));
    var Patient_filtered = patients_tags.filter(function (el) {
      return el.name != null && el.name !== "" && el.name.trim() !== "";
    });
    console.log(Patient_filtered, "pat tags");
    formData.append("patients_tags", JSON.stringify(Patient_filtered));
    if (file) {
      formData.append("image", file);
    }
    formData.append("order", story_data.order);

    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/stories/edit_stories/${story_data._id}`,
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        props.history.push("/postit/story");
        enqueueSnackbar(res.message, { variant: "success" })
      } else {
        setflag(res.message);
        enqueueSnackbar(res.message, { variant: "error" })
      }
    });
  };
  const get_tags = () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/tags/all_tags`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setTags(res.tags);
      }
    });
  };
  const getDefaultUser = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/customer/default_customer`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setDefaultUsers(res.customer);
      }
    });
  };
  const get_users = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/customer/list_all_customers",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        let patients = [];
        let customer = res.customer;
        customer.map((x, i) => {
          if (x.is_patient == true) {
            patients.push(x);
          }
        });
        set_name(patients);
      }
    });
  };
  const get_initial_data = async () => {
    await getDefaultUser();
    await get_tags();
    await get_users();
  };

  const story_detail = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    console.log(props, "PROPS");
    let requestObj = {
      path: `/api/stories/detail_stories/${props.match.params.id}`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE DETAIL");
      setLaoding(true);
      if (res.code == 200) {
        set_stroy_data(res.stories);
        setStroryImage(res.stories.image);
        setStatus(res.stories.status);
        setShortDescription(res.stories.description);
        setpatients_tags(res.stories.patients_tags);
        setSelectedCustomer(res.stories.user_info);
        var expiryDate = res.stories.expiry_date_time;
        var publishDate = res.stories.publish_date_time;
        //--------calculating expiry time
        const date1 = new Date(expiryDate);
        const date2 = new Date(publishDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setexpiry(String(diffDays * 24));
      }
      setLaoding(false);
    });
  };
  useEffect(() => {
    story_detail();
    get_initial_data();
  }, []);
  if (laoding == true) {
    return (
      <>
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      </>
    );
  }
  return (
    <Container component="main" maxWidth="xs">
      <ArrowBackIcon
        className={classes.backarrow}
        onClick={handleback}
      ></ArrowBackIcon>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Edit story
        </Typography>
        <form className={classes.form} onSubmit={AddStory}>
          {errorflag && <Alert severity="error">{errorflag}</Alert>}
          <Grid container style={{ flexGrow: 1 }} spacing={0}>
            <Grid xs={5} style={{ marginRight: 10 }}>
              <Autocomplete
                id="users"
                disableClearable
                value={selected_user}
                onChange={(event, value) => {
                  setSelectedCustomer(value);
                }}
                getOptionLabel={(option) => option.name}
                options={default_user}
                className={classes.patient}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose user"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: "search" }}
                  />
                )}
              />
            </Grid>
            <Grid xs={6} style={{ marginTop: 5 }}>
              <>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleClick}
                  style={{ height: 58, marginTop: 10, paddingLeft: 5 }}
                >
                  {expiry}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => handleClose("Expiry Hours")}
                >
                  <MenuItem
                    style={{ width: "300px" }}
                    onClick={() => handleClose(24)}
                  >
                    24
                  </MenuItem>
                  <MenuItem onClick={() => handleClose(48)}>48</MenuItem>
                  <MenuItem onClick={() => handleClose(72)}>72</MenuItem>
                </Menu>
              </>
            </Grid>
          </Grid>
          {/* <Autocomplete
                multiple
                id="tags-filled"
                options={tags.map((option) => option.title)}
                onChange={(event, value) => handle_tag_change(value)}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <>
                      {value[index].split(" ").join("") !== "" ? (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ) : null}
                    </>
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    multiline
                    style={{ marginTop: 20 }}
                    rows={1}
                    rowsMax={4}
                    label="Tags"
                    placeholder="Enter new tag"
                  />
                )}
              /> */}
          <Autocomplete
            multiple
            id="tags-filled"
            options={name}
            value={patients_tags}
            getOptionLabel={(name) => name.name}
            getOptionSelected={(option, value) =>
              option.user_id === value.user_id
            }
            onChange={(event, value) => handle_patiett_tag_change(value)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <>
                  <Chip
                    variant="outlined"
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                </>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                multiline
                style={{ marginTop: 20 }}
                rows={1}
                rowsMax={4}
                label="Patients"
                placeholder="Enter new Patient"
              />
            )}
          />

          <Grid container spacing={3}>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                multiline
                rows={2}
                value={short_description}
                onChange={(e) => setShortDescription(e.target.value)}
                rowsMax={4}
                fullWidth
                label="Description"
                name="description"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl component="fieldset" style={{ marginTop: 30 }}>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={status}
                      onChangeCapture={() => setStatus(!status)}
                    />
                  }
                  label="Status"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
          </Grid>
          {file ? (
            <img
              src={URL.createObjectURL(file)}
              className={classes.blog_img}
            ></img>
          ) : Image ? (
            <img src={image_url + Image} className={classes.blog_img}></img>
          ) : null}
          <Button
            fullWidth
            variant="outlined"
            component="label"
            className={classes.uplaod}
            startIcon={<CloudUploadIcon />}
            color="primary"
          >
            <input
              type="file"
              accept="image/x-png,image/jpeg"
              hidden
              onChange={fileChangedHandler}
            ></input>
            Upload image (946X2048)
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Edit story
          </Button>
        </form>
      </div>
    </Container>
  );
}
