import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { invokeApi } from "../../../bl_libs/ApiCalling";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#d8d433",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
  },
  alert: {
    width: "100%",
    margin: 10,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
}));

export default function Addsecrtery(props) {
  const classes = useStyles();
  const history = useHistory();
  const [email, setemail] = useState("");
  const [name, set_name] = useState("");
  const [password, setpassword] = useState("");
  const [clinic, setClinic] = useState([]);
  const [selected_clinic, setSelectedClinic] = useState({});
  const [errorflag, setflag] = useState("");
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);
  // functions
  const handleemail = (event) => {
    setemail(event.target.value);
  };
  const handlename = (event) => {
    set_name(event.target.value);
  };

  const handlepassword = (event) => {
    setpassword(event.target.value);
  };

  const fileChangedHandler = (e) => {
    if (e.target.files.length) {
      setimage(e.target.files[0]);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = (event) => {
    event.preventDefault();
    setiswaiting(true);
    let formData = new FormData(); //formdata object
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("clinic_id", selected_clinic._id);

    if (Image) {
      formData.append("profile_image", Image);
    }

    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/secretary/add_secrtery",
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      setiswaiting(true);
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        history.goBack();
      } else {
        setiswaiting(false);
        setflag(res.message);
      }
    });
  };
  const get_clinins = () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/clinic/all_clinic",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      setClinic(res.clinic);
      console.log(res, "Response");
    });
  };
  useEffect(() => {
    get_clinins();
  }, []);
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography
            className={classes.txt}
            component="h1"
            variant="h5"
          ></Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <input
                accept="image/x-png,image/jpeg"
                hidden
                id="contained-button-file"
                multiple
                type="file"
                onChange={fileChangedHandler}
              />
              <label htmlFor="contained-button-file">
                <Avatar alt="Remy Sharp" className={classes.avtr} src={file}>
                  <AddAPhotoIcon />
                </Avatar>
                <span style={{ marginLeft: 8 }}>200 X 200</span>
              </label>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  value={name}
                  onChange={handlename}
                  label="Name"
                  name="name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  onChange={handlepassword}
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="email"
                  required
                  fullWidth
                  id="email"
                  onChange={handleemail}
                  label="Email"
                  name="email"
                />
              </Grid>

              {/* <Grid item xs={4}>
                <FormControl component="fieldset" style={{ marginTop: 23 }}>
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        color="primary"
                        checked={status}
                        onClick={() => setStatus(!status)}
                      />
                    }
                    label="Status"
                    labelPlacement="start"
                  />
                </FormControl>
              </Grid> */}
              <Grid item xs={12}>
                <Autocomplete
                  id="clinic"
                  disableClearable
                  value={selected_clinic}
                  onChange={(event, value) => {
                    setSelectedClinic(value);
                  }}
                  getOptionLabel={(option) => option.clinic_name}
                  options={clinic}
                  className={classes.patient}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Clinics"
                      margin="normal"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Secrtery
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
