import React from "react";
import PropTypes from "prop-types";

import { Header, Footer } from "./components";

const Main = (props) => {
  const { children } = props;

  return (
    <div className="App">
      <div className="App-header">
        <Header />
        <main>{children}</main>
      </div>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Main;
