import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { invokeApi } from "../../../bl_libs/ApiCalling";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import { useSnackbar } from "notistack";
const moment = require("moment-timezone");

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#d8d433",
  },
  form: {
    width: "100%",
    marginTop: 20, // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
  },
  alert: {
    width: "100%",
    margin: 10,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  patient: {
    marginTop: -15,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, customer_id, theme) {
  return {
    fontWeight:
      customer_id.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function Editappointment(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme();
  const [data, setData] = useState(props.location.appointment_data);
  const [description, setDescription] = useState(data.description);
  const [name, set_name] = useState([]);
  const [title, setTitle] = useState(data.title);
  const [changes, setchanges] = useState(false);

  const [date, setDate] = useState(data.appointment_date_time);
  const [errorflag, setflag] = useState("");
  const [customer, setCustomer] = React.useState(data.user_id);
  const [status, setStatus] = React.useState(data.status);
  const [iswaiting, setiswaiting] = useState(false);

  // functions

  // functions
  const handleDateChange = (date) => {
    setDate(new moment(date).format("YYYY-MM-DDTHH:mm"));
  };
  const handledescritpion = (event) => {
    setDescription(event.target.value);
    setchanges(true);
  };
  const handlestatus = () => {
    setStatus(!status);
    setchanges(true);
  };
  const handletitle = (event) => {
    setTitle(event.target.value);
    setchanges(true);
  };
  const handlename = (event) => {
    set_name(event.target.value);
  };

  const handleCustomer = (event) => {
    console.log(event.target.value);
    setCustomer(event.target.value);
  };
  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = (event) => {
    event.preventDefault();
    setiswaiting(true);
    let formData = new FormData(); //formdata object
    formData.append("description", description);
    formData.append("appointment_date_time", date);
    formData.append("status", status);
    formData.append("title", title);
    formData.append("user_id", customer.user_id);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/appointment/edit_appointment/${data._id}`,
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      setiswaiting(true);
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        history.goBack();
        setiswaiting(false);
        enqueueSnackbar(res.message, { variant: "success" })
      } else {
        setflag(res.message);
        setiswaiting(false);
        enqueueSnackbar(res.message, { variant: "error" })
      }
    });
  };
  const get_users = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/customer/list_all_customers",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        let patients = [];
        let customer = res.customer;
        customer.map((x, i) => {
          if (x.is_patient == true) {
            patients.push(x);
          }
        });
        set_name(patients);
        var result = res.customer.filter((obj) => {
          return obj.user_id === data.user_id;
        });
        console.log(result, "user");
        setCustomer(result[0]);
      }
    });
  };
  useEffect(() => {
    get_users();
  }, []);
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography component="h1" variant="h5">
            Edit Appointment
          </Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            ></Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  id="patients"
                  disableClearable
                  disabled
                  value={customer}
                  onChange={(event, value) => {
                    setCustomer(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  options={name}
                  className={classes.patient}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Patients"
                      margin="normal"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} style={{ marginBottom: -20, marginTop: -15 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Appointment"
                    format="dd/MM/yyyy hh:mm a"
                    inputVariant="outlined"
                    value={date}
                    disabled
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="text"
                  required
                  value={title}
                  fullWidth
                  id="des"
                  onChange={handletitle}
                  label="Title"
                  name="title"
                />
              </Grid>

              <Grid item xs={4}>
                <FormControl component="fieldset" style={{ marginTop: 25 }}>
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        color="primary"
                        checked={status}
                        onClick={handlestatus}
                      />
                    }
                    label="Status"
                    labelPlacement="start"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="text"
                  multiline
                  rowsMax={3}
                  rows={3}
                  value={description}
                  required
                  fullWidth
                  id="des"
                  onChange={handledescritpion}
                  label="Description"
                  name="emaDescriptionil"
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  disabled={!changes}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Edit Appointment
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
