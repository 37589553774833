import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { FaPenFancy } from "react-icons/fa";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
// core components
import GridItem from "./components/Grid/GridItem.js";
import GridContainer from "./components/Grid/GridContainer.js";
import Card from "./components/Card/Card";
import CardHeader from "./components/Card/CardHeader.js";
import CardIcon from "./components/Card/CardIcon.js";
import CardFooter from "./components/Card/CardFooter.js";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import { useEffect } from "react";
import { invokeApi } from "../../bl_libs/ApiCalling";
import Demo from "./demo";

const useStyles = makeStyles((theme) => ({
  styles,

  root: {
    minWidth: 275,
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginLeft: 10,
    marginTop: 10,
    fontSize: 15,
    fontWeight: "bold",
  },
  profile_img: {
    marginLeft: 10,
    marginTop: 12,
    width: 150,
    height: 150,
  },
  l_grid: {
    marginTop: 8,
    marginLeft: 9,
  },
  cardCategory: {
    color: "black",
  },
  cardTitle: {
    color: "black",
  },
  con: {
    marginLeft: 9,
    fontSize: 14,
    color: "#7C7C7C",
  },
  mnu: {
    cursor: "pointer",
    marginTop: 52,
    marginLeft: 100,
  },
  menudrp: {
    width: 30,
  },
}));

function Dashboard(props) {
  const [allBlogs, setblogs] = React.useState("12");
  const [allUsers, setusers] = React.useState("20");
  const [allBloggers, setbloggers] = React.useState("25");
  const [allStories, setstories] = React.useState("30");

  const [posts, setpost] = useState(true);

  const [file, setProfileImage] = React.useState("");

  const classes = useStyles();
  const getstats = async () => {
    let requestObj = {
      path: "/api/admin/dashboard_state",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    setpost(false);
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      const stats = res.stats;
      setblogs(stats.total_blog);
      setusers(stats.total_customers);
      setbloggers(stats.total_author);
      setstories(stats.total_stories);
    });
  };

  useEffect(() => {
    //getstats();
  }, []);
  return (
    <div>
      {posts == false ? (
        <CircularProgress style={{ marginTop: "20%", marginLeft: "50%" }} />
      ) : (
        <>
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color="primary" stats icon>
                  <CardIcon color="primary">
                    <SupervisorAccountIcon />
                  </CardIcon>
                  <p className={classes.cardCategory}>Users</p>
                  <h3 className={classes.cardTitle}>{allUsers}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}></div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color="success" stats icon>
                  <CardIcon color="primary">
                    <FaPenFancy />
                  </CardIcon>
                  <p className={classes.cardCategory}>Blogs</p>
                  <h3 className={classes.cardTitle}>{allBlogs}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}></div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color="danger" stats icon>
                  <CardIcon color="primary">
                    <PostAddIcon />
                  </CardIcon>
                  <p className={classes.cardCategory}>Stories</p>
                  <h3 className={classes.cardTitle}>{allStories}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}></div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color="danger" stats icon>
                  <CardIcon styles={{ color: "black" }} color="primary">
                    <AccountBoxIcon />
                  </CardIcon>
                  <p className={classes.cardCategory}> Authors</p>
                  <h3 className={classes.cardTitle}>{allBloggers}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}></div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
          <Demo />
        </>
      )}
    </div>
  );
}
export default Dashboard;
