export const born = [
  {
    year: 1995,
    ru: 101,
    ch: 170,
    us: 31,
  },
  {
    year: 1998,
    ru: 200,
    ch: 80,
    us: 90,
  },
  {
    year: 2000,
    ru: 100,
    ch: 70,
    us: 45,
  },
  {
    year: 2001,
    ru: 110,
    ch: 70,
    us: 75,
  },
  {
    year: 2002,
    ru: 95,
    ch: 120,
    us: 25,
  },
  {
    year: 2006,
    ru: 115,
    ch: 50,
    us: 45,
  },
  {
    year: 2007,
    ru: 230,
    ch: 110,
    us: 120,
  },
  {
    year: 2015,
    ru: 89,
    ch: 90,
    us: 19,
  },
];

export const populationPyramid = [
  {
    age: "0-4",
    male: -4.6,
    female: 4.3,
  },
  {
    age: "5-9",
    male: -4.4,
    female: 4.2,
  },
  {
    age: "10-14",
    male: -4.2,
    female: 4.0,
  },
  {
    age: "15-19",
    male: -4.0,
    female: 3.8,
  },
  {
    age: "20-24",
    male: -3.9,
    female: 3.7,
  },
  {
    age: "25-29",
    male: -4.0,
    female: 3.8,
  },
  {
    age: "30-34",
    male: -4.0,
    female: 3.8,
  },
  {
    age: "35-39",
    male: -3.5,
    female: 3.4,
  },
  {
    age: "40-44",
    male: -3.2,
    female: 3.1,
  },
  {
    age: "45-49",
    male: -3.1,
    female: 3.1,
  },
  {
    age: "50-54",
    male: -2.8,
    female: 2.8,
  },
  {
    age: "55-59",
    male: -2.4,
    female: 2.5,
  },
  {
    age: "60-64",
    male: -2.0,
    female: 2.1,
  },
  {
    age: "65-69",
    male: -1.6,
    female: 1.8,
  },
  {
    age: "70-74",
    male: -1.1,
    female: 1.2,
  },
  {
    age: "75-79",
    male: -0.7,
    female: 0.9,
  },
  {
    age: "80-84",
    male: -0.4,
    female: 0.6,
  },
  {
    age: "85-89",
    male: -0.2,
    female: 0.3,
  },
  {
    age: "90+",
    male: -0.1,
    female: 0.1,
  },
];

export const gaming = [
  {
    year: 2012,
    mobile: 18,
    pc: 37,
    console: 45,
  },
  {
    year: 2013,
    mobile: 23,
    pc: 38,
    console: 39,
  },
  {
    year: 2014,
    mobile: 29,
    pc: 36,
    console: 35,
  },
  {
    year: 2015,
    mobile: 34,
    pc: 34,
    console: 32,
  },
  {
    year: 2016,
    mobile: 40,
    pc: 30,
    console: 30,
  },
  {
    year: 2017,
    mobile: 46,
    pc: 27,
    console: 27,
  },
  {
    year: 2018,
    mobile: 51,
    pc: 24,
    console: 25,
  },
];

export const annualVehiclesSales = [
  { year: 2011, USA: 171763, China: 5070 },
  { year: 2012, USA: 53240, China: 9900 },
  { year: 2013, USA: 96700, China: 15340 },
  { year: 2014, USA: 118780, China: 73170 },
  { year: 2015, USA: 113870, China: 307380 },
  { year: 2016, USA: 159620, China: 336000 },
  { year: 2017, USA: 194479, China: 600174 },
];

export const australianMedals = [
  {
    year: 1896,
    gold: 2,
    silver: 0,
    bronze: 0,
  },
  {
    year: 1900,
    gold: 2,
    silver: 0,
    bronze: 3,
  },
  {
    year: 1904,
    gold: 0,
    silver: 0,
    bronze: 0,
  },
  {
    year: 1908,
    gold: 1,
    silver: 2,
    bronze: 2,
  },
  {
    year: 1912,
    gold: 2,
    silver: 2,
    bronze: 3,
  },
  {
    year: 1916,
    gold: 0,
    silver: 0,
    bronze: 0,
  },
  {
    year: 1920,
    gold: 0,
    silver: 2,
    bronze: 1,
  },
  {
    year: 1924,
    gold: 3,
    silver: 1,
    bronze: 2,
  },
  {
    year: 1928,
    gold: 1,
    silver: 2,
    bronze: 1,
  },
  {
    year: 1932,
    gold: 3,
    silver: 1,
    bronze: 1,
  },
  {
    year: 1936,
    gold: 0,
    silver: 0,
    bronze: 1,
  },
  {
    year: 1940,
    gold: 0,
    silver: 0,
    bronze: 0,
  },
  {
    year: 1944,
    gold: 0,
    silver: 0,
    bronze: 0,
  },
  {
    year: 1948,
    gold: 2,
    silver: 6,
    bronze: 5,
  },
  {
    year: 1952,
    gold: 6,
    silver: 2,
    bronze: 3,
  },
  {
    year: 1956,
    gold: 13,
    silver: 8,
    bronze: 14,
  },
  {
    year: 1960,
    gold: 8,
    silver: 8,
    bronze: 6,
  },
  {
    year: 1964,
    gold: 6,
    silver: 2,
    bronze: 10,
  },
  {
    year: 1968,
    gold: 5,
    silver: 7,
    bronze: 5,
  },
  {
    year: 1972,
    gold: 8,
    silver: 7,
    bronze: 2,
  },
  {
    year: 1976,
    gold: 0,
    silver: 1,
    bronze: 4,
  },
  {
    year: 1980,
    gold: 2,
    silver: 2,
    bronze: 5,
  },
  {
    year: 1984,
    gold: 4,
    silver: 8,
    bronze: 12,
  },
  {
    year: 1988,
    gold: 3,
    silver: 6,
    bronze: 5,
  },
  {
    year: 1992,
    gold: 7,
    silver: 9,
    bronze: 11,
  },
  {
    year: 1996,
    gold: 9,
    silver: 9,
    bronze: 23,
  },
  {
    year: 2000,
    gold: 16,
    silver: 25,
    bronze: 17,
  },
  {
    year: 2004,
    gold: 17,
    silver: 16,
    bronze: 16,
  },
  {
    year: 2008,
    gold: 14,
    silver: 15,
    bronze: 17,
  },
];

export const bitcoin = [
  { date: new Date(2015, 10, 15), price: 346 },
  { date: new Date(2015, 11, 17), price: 441 },
  { date: new Date(2016, 0, 16), price: 435 },
  { date: new Date(2016, 1, 21), price: 415 },
  { date: new Date(2016, 3, 25), price: 445 },
  { date: new Date(2016, 4, 29), price: 460 },
  { date: new Date(2016, 5, 20), price: 730 },
  { date: new Date(2016, 7, 5), price: 600 },
  { date: new Date(2016, 8, 14), price: 617 },
  { date: new Date(2016, 9, 30), price: 674 },
  { date: new Date(2016, 11, 21), price: 785 },
  { date: new Date(2017, 0, 8), price: 991 },
  { date: new Date(2017, 1, 9), price: 1028 },
  { date: new Date(2017, 2, 9), price: 1251 },
  { date: new Date(2017, 3, 6), price: 1103 },
  { date: new Date(2017, 4, 4), price: 1392 },
  { date: new Date(2017, 5, 11), price: 2747 },
  { date: new Date(2017, 6, 19), price: 2203 },
  { date: new Date(2017, 7, 18), price: 4114 },
  { date: new Date(2017, 8, 19), price: 3840 },
  { date: new Date(2017, 9, 19), price: 5591 },
  { date: new Date(2017, 10, 14), price: 6716 },
  { date: new Date(2017, 11, 22), price: 17905 },
  { date: new Date(2018, 0, 23), price: 11438 },
  { date: new Date(2018, 1, 10), price: 8125 },
  { date: new Date(2018, 2, 6), price: 11079 },
  { date: new Date(2018, 3, 7), price: 6913 },
  { date: new Date(2018, 4, 9), price: 9511 },
  { date: new Date(2018, 5, 6), price: 7553 },
  { date: new Date(2018, 6, 18), price: 6400 },
];

export const population = [
  {
    state: "USA",
    maleyoung: 29.956,
    malemiddle: 90.354,
    maleolder: 14.472,
    femaleyoung: 28.597,
    femalemiddle: 91.827,
    femaleolder: 20.362,
  },
  {
    state: "Brazil",
    maleyoung: 25.607,
    malemiddle: 55.793,
    maleolder: 3.727,
    femaleyoung: 24.67,
    femalemiddle: 57.598,
    femaleolder: 5.462,
  },
  {
    state: "Russia",
    maleyoung: 13.493,
    malemiddle: 48.983,
    maleolder: 5.802,
    femaleyoung: 12.971,
    femalemiddle: 52.14,
    femaleolder: 12.61,
  },
  {
    state: "Japan",
    maleyoung: 9.575,
    malemiddle: 43.363,
    maleolder: 9.024,
    femaleyoung: 9.105,
    femalemiddle: 42.98,
    femaleolder: 12.501,
  },
  {
    state: "Mexico",
    maleyoung: 17.306,
    malemiddle: 30.223,
    maleolder: 1.927,
    femaleyoung: 16.632,
    femalemiddle: 31.868,
    femaleolder: 2.391,
  },
  {
    state: "Germany",
    maleyoung: 6.679,
    malemiddle: 28.638,
    maleolder: 5.133,
    femaleyoung: 6.333,
    femalemiddle: 27.693,
    femaleolder: 8.318,
  },
  {
    state: "United Kindom",
    maleyoung: 5.816,
    malemiddle: 19.622,
    maleolder: 3.864,
    femaleyoung: 5.519,
    femalemiddle: 19.228,
    femaleolder: 5.459,
  },
];

export const olimpicMedals = [
  {
    country: "USA",
    gold: 3,
    silver: 38,
    bronze: 36,
  },
  {
    country: "China",
    gold: 51,
    silver: 21,
    bronze: 28,
  },
  {
    country: "Russia",
    gold: 23,
    silver: 21,
    bronze: 28,
  },
  {
    country: "Britain",
    gold: 19,
    silver: 13,
    bronze: 15,
  },
  {
    country: "Australia",
    gold: 14,
    silver: 15,
    bronze: 17,
  },
  {
    country: "Germany",
    gold: 16,
    silver: 10,
    bronze: 15,
  },
];

export const confidence = [
  {
    year: 1993,
    tvNews: 19,
    church: 29,
    military: 32,
  },
  {
    year: 1995,
    tvNews: 13,
    church: 32,
    military: 33,
  },
  {
    year: 1997,
    tvNews: 14,
    church: 35,
    military: 30,
  },
  {
    year: 1999,
    tvNews: 13,
    church: 32,
    military: 34,
  },
  {
    year: 2001,
    tvNews: 15,
    church: 28,
    military: 32,
  },
  {
    year: 2003,
    tvNews: 16,
    church: 27,
    military: 48,
  },
  {
    year: 2006,
    tvNews: 12,
    church: 28,
    military: 41,
  },
  {
    year: 2008,
    tvNews: 11,
    church: 26,
    military: 45,
  },
  {
    year: 2010,
    tvNews: 10,
    church: 25,
    military: 44,
  },
  {
    year: 2012,
    tvNews: 11,
    church: 25,
    military: 43,
  },
  {
    year: 2014,
    tvNews: 10,
    church: 25,
    military: 39,
  },
  {
    year: 2016,
    tvNews: 8,
    church: 20,
    military: 41,
  },
  {
    year: 2018,
    tvNews: 10,
    church: 20,
    military: 43,
  },
];

export const energyConsumption = [
  {
    country: "Jan",
    hydro: 59.8,
    oil: 937.6,
    gas: 582,
    coal: 564.3,
    nuclear: 187.9,
  },
  {
    country: "Mar",
    hydro: 74.2,
    oil: 308.6,
    gas: 35.1,
    coal: 956.9,
    nuclear: 11.3,
  },
  {
    country: "May",
    hydro: 40,
    oil: 128.5,
    gas: 361.8,
    coal: 105,
    nuclear: 32.4,
  },
  {
    country: "july",
    hydro: 22.6,
    oil: 241.5,
    gas: 64.9,
    coal: 120.8,
    nuclear: 64.8,
  },
  {
    country: "Sep",
    hydro: 19,
    oil: 119.3,
    gas: 28.9,
    coal: 204.8,
    nuclear: 3.8,
  },
  {
    country: "Dec",
    hydro: 6.1,
    oil: 123.6,
    gas: 77.3,
    coal: 85.7,
    nuclear: 37.8,
  },
];

export const carbonEmmision = [
  {
    year: 1950,
    gas: 97,
    liquids: 423,
    solids: 1070,
    cementProduction: 18,
    gasFlaring: 23,
  },
  {
    year: 1960,
    gas: 227,
    liquids: 849,
    solids: 1410,
    cementProduction: 43,
    gasFlaring: 39,
  },
  {
    year: 1970,
    gas: 493,
    liquids: 1839,
    solids: 1556,
    cementProduction: 78,
    gasFlaring: 87,
  },
  {
    year: 1980,
    gas: 737,
    liquids: 2422,
    solids: 1935,
    cementProduction: 120,
    gasFlaring: 86,
  },
  {
    year: 1990,
    gas: 1026,
    liquids: 2492,
    solids: 2359,
    cementProduction: 157,
    gasFlaring: 40,
  },
  {
    year: 2000,
    gas: 1289,
    liquids: 2845,
    solids: 2327,
    cementProduction: 226,
    gasFlaring: 46,
  },
  {
    year: 2010,
    gas: 1696,
    liquids: 3107,
    solids: 3812,
    cementProduction: 446,
    gasFlaring: 67,
  },
];

export const booksSale = [
  {
    year: 1970,
    mysteries: 9,
    scienceFiction: 16,
    romance: 5,
    fantasy: 4,
    thrillers: 19,
  },
  {
    year: 1971,
    mysteries: 15,
    scienceFiction: 18,
    romance: 2,
    fantasy: 4,
    thrillers: 14,
  },
  {
    year: 1972,
    mysteries: 10,
    scienceFiction: 20,
    romance: 1,
    fantasy: 16,
    thrillers: 11,
  },
  {
    year: 1973,
    mysteries: 12,
    scienceFiction: 17,
    romance: 15,
    fantasy: 5,
    thrillers: 14,
  },
  {
    year: 1974,
    mysteries: 8,
    scienceFiction: 13,
    romance: 10,
    fantasy: 19,
    thrillers: 5,
  },
  {
    year: 1975,
    mysteries: 7,
    scienceFiction: 18,
    romance: 20,
    fantasy: 18,
    thrillers: 12,
  },
  {
    year: 1976,
    mysteries: 12,
    scienceFiction: 12,
    romance: 5,
    fantasy: 2,
    thrillers: 3,
  },
  {
    year: 1977,
    mysteries: 18,
    scienceFiction: 16,
    romance: 1,
    fantasy: 6,
    thrillers: 3,
  },
  {
    year: 1978,
    mysteries: 3,
    scienceFiction: 13,
    romance: 9,
    fantasy: 15,
    thrillers: 11,
  },
  {
    year: 1979,
    mysteries: 11,
    scienceFiction: 13,
    romance: 14,
    fantasy: 1,
    thrillers: 12,
  },
  {
    year: 1980,
    mysteries: 6,
    scienceFiction: 19,
    romance: 16,
    fantasy: 6,
    thrillers: 16,
  },
  {
    year: 1981,
    mysteries: 2,
    scienceFiction: 15,
    romance: 4,
    fantasy: 6,
    thrillers: 13,
  },
  {
    year: 1982,
    mysteries: 4,
    scienceFiction: 17,
    romance: 20,
    fantasy: 7,
    thrillers: 10,
  },
  {
    year: 1983,
    mysteries: 3,
    scienceFiction: 2,
    romance: 18,
    fantasy: 18,
    thrillers: 17,
  },
  {
    year: 1984,
    mysteries: 10,
    scienceFiction: 19,
    romance: 6,
    fantasy: 13,
    thrillers: 11,
  },
  {
    year: 1985,
    mysteries: 16,
    scienceFiction: 6,
    romance: 5,
    fantasy: 2,
    thrillers: 7,
  },
  {
    year: 1986,
    mysteries: 17,
    scienceFiction: 4,
    romance: 8,
    fantasy: 10,
    thrillers: 4,
  },
  {
    year: 1987,
    mysteries: 9,
    scienceFiction: 18,
    romance: 17,
    fantasy: 6,
    thrillers: 9,
  },
  {
    year: 1988,
    mysteries: 6,
    scienceFiction: 8,
    romance: 17,
    fantasy: 13,
    thrillers: 1,
  },
  {
    year: 1989,
    mysteries: 3,
    scienceFiction: 10,
    romance: 13,
    fantasy: 17,
    thrillers: 2,
  },
  {
    year: 1990,
    mysteries: 10,
    scienceFiction: 8,
    romance: 14,
    fantasy: 12,
    thrillers: 7,
  },
  {
    year: 1991,
    mysteries: 18,
    scienceFiction: 1,
    romance: 2,
    fantasy: 8,
    thrillers: 16,
  },
  {
    year: 1992,
    mysteries: 19,
    scienceFiction: 18,
    romance: 11,
    fantasy: 20,
    thrillers: 11,
  },
  {
    year: 1993,
    mysteries: 7,
    scienceFiction: 14,
    romance: 4,
    fantasy: 8,
    thrillers: 10,
  },
  {
    year: 1994,
    mysteries: 2,
    scienceFiction: 18,
    romance: 13,
    fantasy: 11,
    thrillers: 15,
  },
  {
    year: 1995,
    mysteries: 2,
    scienceFiction: 6,
    romance: 7,
    fantasy: 11,
    thrillers: 10,
  },
  {
    year: 1996,
    mysteries: 10,
    scienceFiction: 19,
    romance: 17,
    fantasy: 17,
    thrillers: 9,
  },
  {
    year: 1997,
    mysteries: 3,
    scienceFiction: 7,
    romance: 4,
    fantasy: 15,
    thrillers: 9,
  },
  {
    year: 1998,
    mysteries: 13,
    scienceFiction: 1,
    romance: 7,
    fantasy: 16,
    thrillers: 13,
  },
  {
    year: 1999,
    mysteries: 19,
    scienceFiction: 7,
    romance: 18,
    fantasy: 14,
    thrillers: 1,
  },
  {
    year: 2000,
    mysteries: 2,
    scienceFiction: 11,
    romance: 12,
    fantasy: 11,
    thrillers: 5,
  },
  {
    year: 2001,
    mysteries: 16,
    scienceFiction: 9,
    romance: 12,
    fantasy: 6,
    thrillers: 8,
  },
  {
    year: 2002,
    mysteries: 11,
    scienceFiction: 15,
    romance: 2,
    fantasy: 9,
    thrillers: 3,
  },
  {
    year: 2003,
    mysteries: 1,
    scienceFiction: 6,
    romance: 9,
    fantasy: 9,
    thrillers: 16,
  },
  {
    year: 2004,
    mysteries: 18,
    scienceFiction: 14,
    romance: 3,
    fantasy: 2,
    thrillers: 11,
  },
  {
    year: 2005,
    mysteries: 9,
    scienceFiction: 16,
    romance: 7,
    fantasy: 8,
    thrillers: 20,
  },
  {
    year: 2006,
    mysteries: 18,
    scienceFiction: 2,
    romance: 5,
    fantasy: 6,
    thrillers: 16,
  },
  {
    year: 2007,
    mysteries: 5,
    scienceFiction: 17,
    romance: 14,
    fantasy: 4,
    thrillers: 7,
  },
  {
    year: 2008,
    mysteries: 15,
    scienceFiction: 2,
    romance: 12,
    fantasy: 3,
    thrillers: 8,
  },
  {
    year: 2009,
    mysteries: 16,
    scienceFiction: 20,
    romance: 7,
    fantasy: 14,
    thrillers: 11,
  },
  {
    year: 2010,
    mysteries: 14,
    scienceFiction: 11,
    romance: 3,
    fantasy: 1,
    thrillers: 6,
  },
  {
    year: 2011,
    mysteries: 18,
    scienceFiction: 20,
    romance: 5,
    fantasy: 4,
    thrillers: 5,
  },
  {
    year: 2012,
    mysteries: 3,
    scienceFiction: 3,
    romance: 10,
    fantasy: 5,
    thrillers: 18,
  },
  {
    year: 2013,
    mysteries: 1,
    scienceFiction: 1,
    romance: 0,
    fantasy: 6,
    thrillers: 16,
  },
  {
    year: 2014,
    mysteries: 15,
    scienceFiction: 7,
    romance: 1,
    fantasy: 2,
    thrillers: 20,
  },
  {
    year: 2015,
    mysteries: 12,
    scienceFiction: 19,
    romance: 3,
    fantasy: 3,
    thrillers: 12,
  },
  {
    year: 2016,
    mysteries: 11,
    scienceFiction: 20,
    romance: 11,
    fantasy: 5,
    thrillers: 17,
  },
  {
    year: 2017,
    mysteries: 4,
    scienceFiction: 20,
    romance: 3,
    fantasy: 12,
    thrillers: 5,
  },
];

export const oilProduction = [
  {
    year: "1970",
    saudiArabia: 241.142,
    usa: 482.15,
    iran: 230.174,
    mexico: 23.64,
    price: 17,
    consumption: 570,
  },
  {
    year: "1980",
    saudiArabia: 511.334,
    usa: 437.343,
    iran: 75.097,
    mexico: 108.249,
    price: 104,
    consumption: 630,
  },
  {
    year: "1990",
    saudiArabia: 324.359,
    usa: 374.867,
    iran: 165.284,
    mexico: 141.06,
    russia: 516.04,
    price: 23.7,
    consumption: 590,
  },
  {
    year: "2000",
    saudiArabia: 410.06,
    usa: 297.513,
    iran: 196.877,
    mexico: 159.63,
    russia: 312.821,
    price: 28.3,
    consumption: 680,
  },
  {
    year: "2010",
    saudiArabia: 413.505,
    usa: 279.225,
    iran: 200.318,
    mexico: 144.975,
    russia: 487.106,
    price: 79.6,
    consumption: 640,
  },
  {
    year: "2015",
    saudiArabia: 516.157,
    usa: 437.966,
    iran: 142.087,
    mexico: 121.587,
    russia: 512.777,
    price: 52.4,
    consumption: 790,
  },
];

export const ageStructure = [
  {
    state: "Germany",
    young: 6.7,
    middle: 28.6,
    older: 5.1,
  },
  {
    state: "Japan",
    young: 9.6,
    middle: 43.4,
    older: 9,
  },
  {
    state: "Russia",
    young: 13.5,
    middle: 49,
    older: 5.8,
  },
  {
    state: "USA",
    young: 30,
    middle: 90.3,
    older: 14.5,
  },
];

export const contributors = [
  {
    login: "kvet",
    id: 929987,
    avatar_url: "https://avatars2.githubusercontent.com/u/929987?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/kvet",
    html_url: "https://github.com/kvet",
    followers_url: "https://api.github.com/users/kvet/followers",
    following_url: "https://api.github.com/users/kvet/following{/other_user}",
    gists_url: "https://api.github.com/users/kvet/gists{/gist_id}",
    starred_url: "https://api.github.com/users/kvet/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/kvet/subscriptions",
    organizations_url: "https://api.github.com/users/kvet/orgs",
    repos_url: "https://api.github.com/users/kvet/repos",
    events_url: "https://api.github.com/users/kvet/events{/privacy}",
    received_events_url: "https://api.github.com/users/kvet/received_events",
    type: "User",
    site_admin: false,
    contributions: 301,
  },
  {
    login: "SergeyAlexeev",
    id: 1897913,
    avatar_url: "https://avatars3.githubusercontent.com/u/1897913?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/SergeyAlexeev",
    html_url: "https://github.com/SergeyAlexeev",
    followers_url: "https://api.github.com/users/SergeyAlexeev/followers",
    following_url:
      "https://api.github.com/users/SergeyAlexeev/following{/other_user}",
    gists_url: "https://api.github.com/users/SergeyAlexeev/gists{/gist_id}",
    starred_url:
      "https://api.github.com/users/SergeyAlexeev/starred{/owner}{/repo}",
    subscriptions_url:
      "https://api.github.com/users/SergeyAlexeev/subscriptions",
    organizations_url: "https://api.github.com/users/SergeyAlexeev/orgs",
    repos_url: "https://api.github.com/users/SergeyAlexeev/repos",
    events_url: "https://api.github.com/users/SergeyAlexeev/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/SergeyAlexeev/received_events",
    type: "User",
    site_admin: false,
    contributions: 113,
  },
  {
    login: "gsobolev",
    id: 4580098,
    avatar_url: "https://avatars0.githubusercontent.com/u/4580098?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/gsobolev",
    html_url: "https://github.com/gsobolev",
    followers_url: "https://api.github.com/users/gsobolev/followers",
    following_url:
      "https://api.github.com/users/gsobolev/following{/other_user}",
    gists_url: "https://api.github.com/users/gsobolev/gists{/gist_id}",
    starred_url: "https://api.github.com/users/gsobolev/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/gsobolev/subscriptions",
    organizations_url: "https://api.github.com/users/gsobolev/orgs",
    repos_url: "https://api.github.com/users/gsobolev/repos",
    events_url: "https://api.github.com/users/gsobolev/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/gsobolev/received_events",
    type: "User",
    site_admin: false,
    contributions: 70,
  },
  {
    login: "dxbykov",
    id: 8307562,
    avatar_url: "https://avatars2.githubusercontent.com/u/8307562?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/dxbykov",
    html_url: "https://github.com/dxbykov",
    followers_url: "https://api.github.com/users/dxbykov/followers",
    following_url:
      "https://api.github.com/users/dxbykov/following{/other_user}",
    gists_url: "https://api.github.com/users/dxbykov/gists{/gist_id}",
    starred_url: "https://api.github.com/users/dxbykov/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/dxbykov/subscriptions",
    organizations_url: "https://api.github.com/users/dxbykov/orgs",
    repos_url: "https://api.github.com/users/dxbykov/repos",
    events_url: "https://api.github.com/users/dxbykov/events{/privacy}",
    received_events_url: "https://api.github.com/users/dxbykov/received_events",
    type: "User",
    site_admin: false,
    contributions: 63,
  },
  {
    login: "MaximKudriavtsev",
    id: 15093892,
    avatar_url: "https://avatars2.githubusercontent.com/u/15093892?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/MaximKudriavtsev",
    html_url: "https://github.com/MaximKudriavtsev",
    followers_url: "https://api.github.com/users/MaximKudriavtsev/followers",
    following_url:
      "https://api.github.com/users/MaximKudriavtsev/following{/other_user}",
    gists_url: "https://api.github.com/users/MaximKudriavtsev/gists{/gist_id}",
    starred_url:
      "https://api.github.com/users/MaximKudriavtsev/starred{/owner}{/repo}",
    subscriptions_url:
      "https://api.github.com/users/MaximKudriavtsev/subscriptions",
    organizations_url: "https://api.github.com/users/MaximKudriavtsev/orgs",
    repos_url: "https://api.github.com/users/MaximKudriavtsev/repos",
    events_url:
      "https://api.github.com/users/MaximKudriavtsev/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/MaximKudriavtsev/received_events",
    type: "User",
    site_admin: false,
    contributions: 54,
  },
  {
    login: "viterobk",
    id: 15941408,
    avatar_url: "https://avatars3.githubusercontent.com/u/15941408?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/viterobk",
    html_url: "https://github.com/viterobk",
    followers_url: "https://api.github.com/users/viterobk/followers",
    following_url:
      "https://api.github.com/users/viterobk/following{/other_user}",
    gists_url: "https://api.github.com/users/viterobk/gists{/gist_id}",
    starred_url: "https://api.github.com/users/viterobk/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/viterobk/subscriptions",
    organizations_url: "https://api.github.com/users/viterobk/orgs",
    repos_url: "https://api.github.com/users/viterobk/repos",
    events_url: "https://api.github.com/users/viterobk/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/viterobk/received_events",
    type: "User",
    site_admin: false,
    contributions: 27,
  },
  {
    login: "AnnaTikh",
    id: 26564321,
    avatar_url: "https://avatars2.githubusercontent.com/u/26564321?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/AnnaTikh",
    html_url: "https://github.com/AnnaTikh",
    followers_url: "https://api.github.com/users/AnnaTikh/followers",
    following_url:
      "https://api.github.com/users/AnnaTikh/following{/other_user}",
    gists_url: "https://api.github.com/users/AnnaTikh/gists{/gist_id}",
    starred_url: "https://api.github.com/users/AnnaTikh/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/AnnaTikh/subscriptions",
    organizations_url: "https://api.github.com/users/AnnaTikh/orgs",
    repos_url: "https://api.github.com/users/AnnaTikh/repos",
    events_url: "https://api.github.com/users/AnnaTikh/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/AnnaTikh/received_events",
    type: "User",
    site_admin: false,
    contributions: 3,
  },
  {
    login: "oliversturm",
    id: 4730969,
    avatar_url: "https://avatars2.githubusercontent.com/u/4730969?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/oliversturm",
    html_url: "https://github.com/oliversturm",
    followers_url: "https://api.github.com/users/oliversturm/followers",
    following_url:
      "https://api.github.com/users/oliversturm/following{/other_user}",
    gists_url: "https://api.github.com/users/oliversturm/gists{/gist_id}",
    starred_url:
      "https://api.github.com/users/oliversturm/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/oliversturm/subscriptions",
    organizations_url: "https://api.github.com/users/oliversturm/orgs",
    repos_url: "https://api.github.com/users/oliversturm/repos",
    events_url: "https://api.github.com/users/oliversturm/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/oliversturm/received_events",
    type: "User",
    site_admin: false,
    contributions: 2,
  },
  {
    login: "alexey-semikozov",
    id: 7069422,
    avatar_url: "https://avatars1.githubusercontent.com/u/7069422?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/alexey-semikozov",
    html_url: "https://github.com/alexey-semikozov",
    followers_url: "https://api.github.com/users/alexey-semikozov/followers",
    following_url:
      "https://api.github.com/users/alexey-semikozov/following{/other_user}",
    gists_url: "https://api.github.com/users/alexey-semikozov/gists{/gist_id}",
    starred_url:
      "https://api.github.com/users/alexey-semikozov/starred{/owner}{/repo}",
    subscriptions_url:
      "https://api.github.com/users/alexey-semikozov/subscriptions",
    organizations_url: "https://api.github.com/users/alexey-semikozov/orgs",
    repos_url: "https://api.github.com/users/alexey-semikozov/repos",
    events_url:
      "https://api.github.com/users/alexey-semikozov/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/alexey-semikozov/received_events",
    type: "User",
    site_admin: false,
    contributions: 2,
  },
  {
    login: "dirk-pieterse",
    id: 26871970,
    avatar_url: "https://avatars0.githubusercontent.com/u/26871970?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/dirk-pieterse",
    html_url: "https://github.com/dirk-pieterse",
    followers_url: "https://api.github.com/users/dirk-pieterse/followers",
    following_url:
      "https://api.github.com/users/dirk-pieterse/following{/other_user}",
    gists_url: "https://api.github.com/users/dirk-pieterse/gists{/gist_id}",
    starred_url:
      "https://api.github.com/users/dirk-pieterse/starred{/owner}{/repo}",
    subscriptions_url:
      "https://api.github.com/users/dirk-pieterse/subscriptions",
    organizations_url: "https://api.github.com/users/dirk-pieterse/orgs",
    repos_url: "https://api.github.com/users/dirk-pieterse/repos",
    events_url: "https://api.github.com/users/dirk-pieterse/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/dirk-pieterse/received_events",
    type: "User",
    site_admin: false,
    contributions: 1,
  },
  {
    login: "helsus",
    id: 312055,
    avatar_url: "https://avatars1.githubusercontent.com/u/312055?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/helsus",
    html_url: "https://github.com/helsus",
    followers_url: "https://api.github.com/users/helsus/followers",
    following_url: "https://api.github.com/users/helsus/following{/other_user}",
    gists_url: "https://api.github.com/users/helsus/gists{/gist_id}",
    starred_url: "https://api.github.com/users/helsus/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/helsus/subscriptions",
    organizations_url: "https://api.github.com/users/helsus/orgs",
    repos_url: "https://api.github.com/users/helsus/repos",
    events_url: "https://api.github.com/users/helsus/events{/privacy}",
    received_events_url: "https://api.github.com/users/helsus/received_events",
    type: "User",
    site_admin: false,
    contributions: 1,
  },
  {
    login: "psamim",
    id: 1868679,
    avatar_url: "https://avatars3.githubusercontent.com/u/1868679?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/psamim",
    html_url: "https://github.com/psamim",
    followers_url: "https://api.github.com/users/psamim/followers",
    following_url: "https://api.github.com/users/psamim/following{/other_user}",
    gists_url: "https://api.github.com/users/psamim/gists{/gist_id}",
    starred_url: "https://api.github.com/users/psamim/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/psamim/subscriptions",
    organizations_url: "https://api.github.com/users/psamim/orgs",
    repos_url: "https://api.github.com/users/psamim/repos",
    events_url: "https://api.github.com/users/psamim/events{/privacy}",
    received_events_url: "https://api.github.com/users/psamim/received_events",
    type: "User",
    site_admin: false,
    contributions: 1,
  },
  {
    login: "Krijovnick",
    id: 8238559,
    avatar_url: "https://avatars2.githubusercontent.com/u/8238559?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/Krijovnick",
    html_url: "https://github.com/Krijovnick",
    followers_url: "https://api.github.com/users/Krijovnick/followers",
    following_url:
      "https://api.github.com/users/Krijovnick/following{/other_user}",
    gists_url: "https://api.github.com/users/Krijovnick/gists{/gist_id}",
    starred_url:
      "https://api.github.com/users/Krijovnick/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/Krijovnick/subscriptions",
    organizations_url: "https://api.github.com/users/Krijovnick/orgs",
    repos_url: "https://api.github.com/users/Krijovnick/repos",
    events_url: "https://api.github.com/users/Krijovnick/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/Krijovnick/received_events",
    type: "User",
    site_admin: false,
    contributions: 1,
  },
  {
    login: "tpisto",
    id: 226244,
    avatar_url: "https://avatars0.githubusercontent.com/u/226244?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/tpisto",
    html_url: "https://github.com/tpisto",
    followers_url: "https://api.github.com/users/tpisto/followers",
    following_url: "https://api.github.com/users/tpisto/following{/other_user}",
    gists_url: "https://api.github.com/users/tpisto/gists{/gist_id}",
    starred_url: "https://api.github.com/users/tpisto/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/tpisto/subscriptions",
    organizations_url: "https://api.github.com/users/tpisto/orgs",
    repos_url: "https://api.github.com/users/tpisto/repos",
    events_url: "https://api.github.com/users/tpisto/events{/privacy}",
    received_events_url: "https://api.github.com/users/tpisto/received_events",
    type: "User",
    site_admin: false,
    contributions: 1,
  },
  {
    login: "gerhat",
    id: 11447903,
    avatar_url: "https://avatars3.githubusercontent.com/u/11447903?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/gerhat",
    html_url: "https://github.com/gerhat",
    followers_url: "https://api.github.com/users/gerhat/followers",
    following_url: "https://api.github.com/users/gerhat/following{/other_user}",
    gists_url: "https://api.github.com/users/gerhat/gists{/gist_id}",
    starred_url: "https://api.github.com/users/gerhat/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/gerhat/subscriptions",
    organizations_url: "https://api.github.com/users/gerhat/orgs",
    repos_url: "https://api.github.com/users/gerhat/repos",
    events_url: "https://api.github.com/users/gerhat/events{/privacy}",
    received_events_url: "https://api.github.com/users/gerhat/received_events",
    type: "User",
    site_admin: false,
    contributions: 1,
  },
  {
    login: "paiboon15721",
    id: 4963061,
    avatar_url: "https://avatars3.githubusercontent.com/u/4963061?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/paiboon15721",
    html_url: "https://github.com/paiboon15721",
    followers_url: "https://api.github.com/users/paiboon15721/followers",
    following_url:
      "https://api.github.com/users/paiboon15721/following{/other_user}",
    gists_url: "https://api.github.com/users/paiboon15721/gists{/gist_id}",
    starred_url:
      "https://api.github.com/users/paiboon15721/starred{/owner}{/repo}",
    subscriptions_url:
      "https://api.github.com/users/paiboon15721/subscriptions",
    organizations_url: "https://api.github.com/users/paiboon15721/orgs",
    repos_url: "https://api.github.com/users/paiboon15721/repos",
    events_url: "https://api.github.com/users/paiboon15721/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/paiboon15721/received_events",
    type: "User",
    site_admin: false,
    contributions: 1,
  },
];

export const sales = {
  2017: [
    { month: "Jan", sale: 50, total: 987 },
    { month: "Feb", sale: 100, total: 3000 },
    { month: "Mar", sale: 30, total: 1100 },
    { month: "Apr", sale: 107, total: 7100 },
    { month: "May", sale: 95, total: 4300 },
    { month: "Jun", sale: 150, total: 7500 },
    { month: "Jul", sale: 120, total: 5300 },
    { month: "Aug", sale: 110, total: 2500 },
    { month: "Sep", sale: 54, total: 2300 },
    { month: "Oct", sale: 129, total: 2600 },
    { month: "Nov", sale: 48, total: 3400 },
    { month: "Dec", sale: 43, total: 3200 },
  ],
  2018: [
    { month: "Jan", sale: 100, total: 1000 },
    { month: "Feb", sale: 200, total: 4300 },
    { month: "Mar", sale: 50, total: 1200 },
    { month: "Apr", sale: 127, total: 7150 },
    { month: "May", sale: 105, total: 4340 },
    { month: "Jun", sale: 180, total: 7520 },
    { month: "Jul", sale: 150, total: 5380 },
    { month: "Aug", sale: 120, total: 2590 },
    { month: "Sep", sale: 59, total: 2700 },
    { month: "Oct", sale: 139, total: 2800 },
    { month: "Nov", sale: 66, total: 3450 },
    { month: "Dec", sale: 55, total: 3260 },
  ],
  2019: [
    { month: "Jan", sale: 170, total: 856 },
    { month: "Feb", sale: 150, total: 3574 },
    { month: "Mar", sale: 10, total: 1198 },
    { month: "Apr", sale: 33, total: 6150 },
    { month: "May", sale: 84, total: 3340 },
    { month: "Jun", sale: 120, total: 5520 },
    { month: "Jul", sale: 110, total: 3380 },
    { month: "Aug", sale: 90, total: 1890 },
    { month: "Sep", sale: 29, total: 1900 },
    { month: "Oct", sale: 118, total: 2300 },
    { month: "Nov", sale: 48, total: 3198 },
    { month: "Dec", sale: 12, total: 2410 },
  ],
};
