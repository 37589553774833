import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CircularProgress from "@material-ui/core/CircularProgress";
import { invokeApi } from "../../../../bl_libs/ApiCalling";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import CKEditor from "./editor/CKEditor";
import Box from "@material-ui/core/Box";
import CancelIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { base_uri, image_url } from "../../../../bl_libs/ApiCalling";
import { useSnackbar } from 'notistack';

var moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 600, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    height: 55,
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  uplaod: {
    marginTop: 20,
  },
  blog_img: {
    height: "90%",
  },
  formControl: {
    marginTop: 10,
  },
  backarrow: {
    marginInlineStart: "-100px",
    cursor: "pointer",
    marginTop: "2%",
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
  input: {
    marginLeft: "40%",
  },
}));

export default function AddPhoto(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
  const [status, setStatus] = React.useState(false);
  const [photo_add, setPhotoAdd] = React.useState(false);
  const [default_user, setDefaultUsers] = React.useState([]);
  const [selected_user, setSelectedCustomer] = React.useState();
  const [short_description, setShortDescription] = React.useState("");
  const [file, setPhoto] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [final_tags, setFinalTags] = React.useState([]);
  const [errorflag, setflag] = React.useState("");
  const [Savepublish, setSavePublish] = React.useState(false);
  const [name, set_name] = React.useState([]);
  const [patients_tags, setpatients_tags] = React.useState([]);
  const [previewArray, setPreviewArray] = useState([])
  const [photosArray, setPhotosArray] = React.useState([]);
  const [is_photo_uplaod, setIsPhotoUplaod] = React.useState(false);
  const [isdiabled, setisdiabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleback = () => {
    history.goBack();
  };
  const handleChangeInCkEdditor = (text) => {
    setShortDescription(text);
  };

  const update_image = (event, i) => {
    setIsPhotoUplaod(true)
    setIsLoading(true)
    let formData = new FormData();
    console.log("Image upload");
    formData.append("image", event.target.files[0]);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/app_api/upload_CKimage",
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE EDIT IMAGE");
      if (res.code == 200) {
        setPreviewArray(prev => {
          let temp = prev;
          temp[i] = URL.createObjectURL(event.target.files[0])
          return temp
        })
        setIsLoading(false)
        setIsPhotoUplaod(false)
        enqueueSnackbar(res.message, { variant: "success" })
      }
      else {
        setIsLoading(false)
        setIsPhotoUplaod(false)
        enqueueSnackbar(res.message, { variant: "error" })
      }
    });
  };

  const fileChangedHandler = async (event) => {
    setIsLoading(true)
    setPhoto(event.target.files[0]);

    setIsPhotoUplaod(true);
    let formData = new FormData();
    console.log("Image upload");
    formData.append("image", event.target.files[0]);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/app_api/upload_CKimage_with_size",
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setIsLoading(false)
        setPhotosArray([...photosArray, res.image]);
        if (photosArray.length > 5) {
          setisdiabled(true);
        }
        setPreviewArray(prev => [...prev, URL.createObjectURL(event.target.files[0])])
        setPhoto(res.image);
        setIsPhotoUplaod(false);
        enqueueSnackbar(res.message, { variant: "success" })
      }
      else {
        setIsLoading(false)
        setIsPhotoUplaod(false);
        enqueueSnackbar(res.message, { variant: "error" })
      }
    });
  };

  const handleSavePublishChange = (event) => {
    setSavePublish(event.target.value);
    console.log(event.target.value, "save or publish");
  };
  const handle_tag_change = (value) => {
    console.log(value, "value");
    let _tags = [];
    value.map((x, i) => {
      _tags.push({ title: x });
    });
    setFinalTags(_tags);
    console.log(_tags, "final tags");
  };

  const handle_patiett_tag_change = (value) => {
    console.log(value, "value");
    let _patTag = [];
    value.map((x, i) => {
      _patTag.push({ name: x.name, user_id: x.user_id });
    });
    setpatients_tags(_patTag);
    console.log(_patTag, "final tags");
  };

  const handleImageRemove = (index) => {
    console.log(index, "INDEX");
    let images_array = [];
    photosArray.map((x, i) => {
      if (i != index) {
        images_array.push(x);
      }
    });
    setPreviewArray(prev => prev.filter((img, i) => i !== index))
    if (images_array.length < 6) {
      setisdiabled(false)
    }
    setPhotosArray(images_array);
  };

  const Addphoto = (event) => {
    event.preventDefault();
    if (photosArray.length == 0) {
      setflag("Please uplaod photo");
      return;
    }
    if (short_description == "") {
      setflag("Description is not allowed to be empty");
    }
    setPhotoAdd(true);
    let formData = new FormData(); //formdata object
    formData.append("user_id", selected_user._id);
    formData.append("status", status);
    var filtered = final_tags.filter(function (el) {
      return el.title != null && el.title !== "" && el.title.trim() !== "";
    });
    formData.append("tags", JSON.stringify(filtered));
    var Patient_filtered = patients_tags.filter(function (el) {
      return el.name != null && el.name !== "" && el.name.trim() !== "";
    });
    formData.append("patients_tags", JSON.stringify(Patient_filtered));
    formData.append("description", short_description);
    console.log("DATE", Date().toLocaleString());
    formData.append("save_or_publish", Savepublish);
    console.log(photosArray, "photo_array");
    formData.append("photos", photosArray);
    const data = {
      user_id: selected_user._id,
      status: status,
      tags: filtered,
      patients_tags: Patient_filtered,
      description: short_description,
      save_or_publish: Savepublish,
      photos: photosArray.reverse(),
    };
    // if (file) {
    //   formData.append("image", file);
    // }
    console.log(data, "asdjkasd")
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/photo/add_photo",
      method: "POST",
      postData: data,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        props.history.push("/postit/photos");
      } else {
        setPhotoAdd(false);
        setflag(res.message);
      }
    });
  };

  const get_tags = () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/tags/all_tags`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setTags(res.tags);
      }
    });
  };
  const getDefaultUser = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/customer/default_customer`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "DEFAULT CUSTOMER");
      if (res.code == 200) {
        const default_user = [];
        res.customer.map((x, i) => {
          if (x.status == "true" || x.status == true) {
            default_user.push(x);
          }
        });
        console.log(default_user, "default_user");
        setDefaultUsers(default_user);
      }
    });
  };
  const get_users = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/customer/list_all_customers",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        let patients = [];
        let customer = res.customer;
        customer.map((x, i) => {
          if (x.is_patient == true) {
            patients.push(x);
          }
        });
        set_name(patients);
      }
    });
  };
  const get_initial_data = async () => {
    await getDefaultUser();
    await get_tags();
    await get_users();
  };

  useEffect(() => {
    get_initial_data();
  }, []);
  useEffect(() => {
    console.log("photos array refreshed", photosArray)
  }, [photosArray]);

  // if (is_photo_uplaod == false) {
  //   return <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
  // }
  return (
    <>
      {photo_add == true ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="xs">
          <ArrowBackIcon
            className={classes.backarrow}
            onClick={handleback}
          ></ArrowBackIcon>
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Add photo
            </Typography>
            <form className={classes.form} onSubmit={Addphoto}>
              {errorflag && (
                <Alert severity="error" style={{ marginBottom: 10 }}>
                  {errorflag}
                </Alert>
              )}
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Autocomplete
                    id="users"
                    disableClearable
                    value={selected_user}
                    onChange={(event, value) => {
                      setSelectedCustomer(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    options={default_user}
                    className={classes.patient}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Choose user"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl component="fieldset" style={{ marginTop: 10 }}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="primary"
                          checked={status}
                          onClick={() => setStatus(!status)}
                        />
                      }
                      label="Status"
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Autocomplete
                multiple
                id="tags-filled"
                options={name}
                getOptionLabel={(name) => name.name}
                onChange={(event, value) => handle_patiett_tag_change(value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    multiline
                    style={{ marginTop: 20 }}
                    rows={1}
                    rowsMax={4}
                    label="Patients"
                    placeholder="Enter new Patient"
                  />
                )}
              />

              {/* <TextField
                variant="outlined"
                margin="normal"
                multiline
                rows={2}
                rowsMax={4}
                value={short_description}
                onChange={(e) => setShortDescription(e.target.value)}
                fullWidth
                label="Description"
                name="description"
              /> */}
              <div style={{ marginTop: 20 }}>
                <CKEditor
                  content={short_description}
                  setContent={handleChangeInCkEdditor}
                  required={true}
                />
              </div>

              {/* {photosArray.length > 0 ? (
                <img
                  key={Math.random()}
                  alt={"Photo image"}
                  src={image_url + file}
                  style={{ width: 600 }}
                ></img>
              ) : null} */}

              <Box
                display="flex"
                style={{ "overflowY": "scroll" }}
                flexDirection="row"
                p={1}
                m={1}
              >
                {isLoading == false ? previewArray?.map((x, i) => {
                  return (
                    <Box
                      p={1}
                      key={i}
                      style={{
                        Width: 500,
                        height: 400,
                        cursor: "pointer",
                        justifyContent: "center",
                      }}
                    >
                      <Button

                        variant="contained"
                        component="label"
                        style={{
                          marginBottom: -100,
                          marginLeft: 20,
                          backgroundColor: "rgb(0 0 0 / 10%)",
                          boxShadow: "0px 0px 0px 0px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={(e) => update_image(e, i)}
                        ></input>

                        <EditIcon ></EditIcon>
                      </Button>
                      <Button

                        variant="contained"
                        component="label"
                        style={{
                          marginBottom: -100,
                          marginLeft: 5,
                          backgroundColor: "rgb(0 0 0 / 10%)",
                          boxShadow: "0px 0px 0px 0px",
                        }}
                        onClick={() => handleImageRemove(i)}
                      >
                        <ClearOutlinedIcon

                        ></ClearOutlinedIcon>
                      </Button>
                      <img

                        alt={"Photos"}
                        src={x}
                        className={classes.blog_img}
                      />
                    </Box>
                  );
                }) : (
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    <CircularProgress />
                  </div>
                )}
              </Box>
              <Button
                fullWidth
                variant="outlined"
                component="label"
                className={classes.uplaod}
                startIcon={<CloudUploadIcon />}
                color="primary"
              >
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  disabled={isdiabled}
                  onChange={(e) => fileChangedHandler(e)}
                ></input>
                Upload image (600X500)
              </Button>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Save or Publish
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={Savepublish}
                      fullWidth
                      onChange={handleSavePublishChange}
                      label="Save or Publish"
                    >
                      <MenuItem value={false}>Save</MenuItem>
                      <MenuItem value={true}>Publish</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={is_photo_uplaod}
                  >
                    Add photo
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
