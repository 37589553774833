import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Avatar from "@material-ui/core/Avatar";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

import { invokeApi, image_url } from "../../../bl_libs/ApiCalling";
import { useSnackbar } from "notistack";
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
  },
  alert: {
    width: "100%",
  },
  avtr: {
    marginTop: 20,
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
  circle_top:{
    borderRadius:"50%",
    backgroundColor:"#d8d433",
    position: "relative",
    height:100,
    width:100,
    marginTop:20,
    cursor:"pointer"
  },
  image_in_circle:{
    width:"100px",
    paddingLeft:5,
    paddingRight:5,
    position: "absolute",
    top: "50%",
  "-ms-transform": "translateY(-50%)",
   "transform": "translateY(-50%)",
  }
}));
export default function Edit_clinic(props) {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar()
  const [clinci_data, setClinic] = React.useState(props.location.clinic_data);
  const [users, setUsers] = React.useState([]);
  const [selected_user, setSelectedUser] = React.useState({
    user_id: clinci_data.user_id,
  });
  const [email, setEmail] = React.useState(clinci_data.user_id.email);
  const [errorflag, setflag] = React.useState("");
  const [doc_name, setDocName] = React.useState(clinci_data.doctor_name);
  const [description, setDescription] = React.useState(clinci_data.description);
  const [address, setAddress] = React.useState(clinci_data.address);
  const [clinic_name, setClinicName] = React.useState(clinci_data.clinic_name);
  const [status, setStatus] = React.useState(clinci_data.status);
  const [clinic_image, setClinicImage] = React.useState(
    clinci_data.clinic_image
  );
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const handleback = () => {
    history.goBack();
  };
  const fileChangedHandler = (e) => {
    setClinicImage("");
    if (e.target.files.length) {
      setimage(e.target.files[0]);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handlesubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(); //formdata object
    formData.append("clinic_name", clinic_name);
    formData.append("email", email);
    formData.append("doctor_name", doc_name);
    formData.append("description", description);
    formData.append("address", address);
    formData.append("status", status);
    if (Image) {
      formData.append("clinic_image", Image);
    }

    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };

    let requestObj = {
      path: `/api/clinic/edit_clinic/${clinci_data._id}`,
      method: "PUT",
      headers: header,
      postData: formData,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE make patient");
      if (res.code == 200) {
        history.goBack();
        enqueueSnackbar("Clinic detail edited successfully!", { variant: "success" })
      } else {
        setflag(res.message);
        enqueueSnackbar(res.message, { variant: "error" })
      }
    });
  };
  const get_secrteries = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/secretary/list_secrtery",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      setUsers(res.secrtery);
      var result = res.secrtery.filter((obj) => {
        return obj.user_id === clinci_data.secrtery_id;
      });
      console.log(result, "user");
      setSelectedUser(result[0]);
    });
  };
  useEffect(() => {
    get_secrteries();
  }, []);
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            marginLeft: "-550px",
          }}
          onClick={handleback}
        />
        <Typography className={classes.txt} component="h1" variant="h5">
          Edit Clinic
        </Typography>
        {errorflag && (
          <Alert className={classes.alert} severity="error">
            {errorflag}
          </Alert>
        )}
        <form className={classes.form} onSubmit={handlesubmit}>
          <Grid container spacing={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <input
                accept="image/x-png,image/jpeg"
                hidden
                id="contained-button-file"
                multiple
                type="file"
                onChange={fileChangedHandler}
              />
              <label htmlFor="contained-button-file">
                {/* <Avatar
                  alt="Remy Sharp"
                  className={classes.avtr}
                  src={clinic_image !== "" ? image_url + clinic_image : file}
                >
                  <AddAPhotoIcon />
                </Avatar> */}
                <div className={classes.circle_top}>
                  <img  className={classes.image_in_circle} src={clinic_image !== "" ? image_url + clinic_image : file}>
                  </img>
                </div>
                <span style={{ marginLeft: 8 }}>200 X 200</span>
              </label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                required
                id="standard-required"
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {/* <Autocomplete
                id="patients"
                disableClearable
                value={selected_user}
                onChange={(event, value) => setSelectedUser(value)}
                getOptionLabel={(option) => option.name}
                options={users}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Secrtery"
                    margin="normal"
                    variant="outlined"
                    required
                    InputProps={{ ...params.InputProps, type: "search" }}
                  />
                )}
              /> */}
            </Grid>
            <Grid xs={4}>
              <FormControl component="fieldset" style={{ marginTop: 35 }}>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={status}
                      onClick={() => setStatus(!status)}
                    />
                  }
                  label="Status"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="standard-required"
                fullWidth
                label="Cinic Name"
                variant="outlined"
                value={clinic_name}
                onChange={(e) => setClinicName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="standard-required"
                fullWidth
                label="Secrtery Name"
                variant="outlined"
                value={doc_name}
                onChange={(e) => setDocName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="standard-required"
                fullWidth
                multiline
                label="Address"
                rows={3}
                variant="outlined"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="standard-required"
                fullWidth
                multiline
                label="Description"
                rows={3}
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Edit Clinic
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
