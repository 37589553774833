import React from "react";
import Logoimage from "../../../../assets/images/post.png";
const Header = () => (
  <div>
    <h2 className="minima-logo">
      <img src={Logoimage} style={{ width: 40, marginTop: -10 }}></img>
    </h2>
  </div>
);

export default Header;
