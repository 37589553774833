import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import CKEditor from "./editor/CKEditor";
import { invokeApi } from "../../../bl_libs/ApiCalling";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function Setting() {
  const classes = useStyles();
  const [Support_email, setSupportEmail] = React.useState("");
  const [Terms, setTerms] = React.useState("");
  const [Privacy, setPrivacy] = React.useState("");
  const [data, setData] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handlesubmit = async e => {
    e.preventDefault();
    console.log("in submit");
    setError(false);
    setSuccess(false);
    const _data_obj = {
      privacy: Privacy,
      terms: Terms,
      support_email: Support_email
    };
    const header = {
      "x-sh-auth": localStorage.getItem("token")
    };
    let requestObj = {
      path: `/api/setting/setting_edit/${data._id}`,
      method: "POST",
      headers: header,
      postData: _data_obj
    };
    invokeApi(requestObj).then(res => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setSuccess(true);
      } else {
        setError(false);
      }
    });
  };
  const get_initial_data = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token")
    };
    let requestObj = {
      path: `/api/setting/setting_detail`,
      method: "GET",
      headers: header
    };
    invokeApi(requestObj).then(res => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setData(res.setting);
        const data = res.setting;
        setSupportEmail(data.support_email);
        setTerms(data.terms);
        setPrivacy(data.privacy);
      }
    });
  };

  useEffect(() => {
    get_initial_data();
  }, []);
  return (
    <>
      {data == "" ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Setting
            </Typography>
            <form className={classes.form} onSubmit={handlesubmit}>
              {success == true ? (
                <Alert severity="success">Setting Change successfully</Alert>
              ) : null}
              {error == true ? (
                <Alert severity="error">Something get wrong</Alert>
              ) : null}

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="support_email"
                label="Support Email"
                value={Support_email}
                onChange={e => setSupportEmail(e.target.value)}
                name="support_email"
                autoFocus
              />
              {/* <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="privacy"
                value={Privacy}
                onChange={e => setPrivacy(e.target.value)}
                label="Privacy Policy"
                id="privacy"
              /> */}

              <div style={{ marginTop: 20 }}>
                <p style={{ fontWeight: "bold" }}>Privacy Policy</p>
                <CKEditor
                  key={Math.random()}
                  content={Privacy}
                  setContent={(text) => setPrivacy(text)}
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <p style={{ fontWeight: "bold" }}>Terms of use</p>
                <CKEditor
                  key={Math.random()}
                  content={Terms}
                  setContent={(text) => setTerms(text)}
                />
              </div>
              {/* <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={Terms}
                onChange={e => setTerms(e.target.value)}
                name="terms"
                label="Terms of use"
                id="privacy"
              /> */}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Edit
              </Button>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
