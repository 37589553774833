import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { PublicRouteWithLayout, PrivateRouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  Login as LoginPage,
  Route as HomeRoute,
  NotFound as NotFoundPage,
} from "./pages";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />
      <PublicRouteWithLayout
        component={LoginPage}
        exact
        layout={MainLayout}
        path="/login"
      />
      <PrivateRouteWithLayout
        component={HomeRoute}
        layout={MinimalLayout}
        path="/postit"
      />
      <PublicRouteWithLayout
        component={NotFoundPage}
        exact={false}
        layout={MainLayout}
        path="/"
      />
    </Switch>
  );
};

export default Routes;
