import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CKEditor from "./editor/CKEditor";
import { useHistory } from "react-router-dom";
import Select from "@material-ui/core/Select";
import makeAnimated from "react-select/animated";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { invokeApi, image_url } from "../../../../bl_libs/ApiCalling";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 600, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    height: 55,
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
  },
  uplaod: {
    marginTop: 20,
  },
  blog_img: {
    marginTop: 10,
    width: 600,
    height: 300,
  },
  formControl: {
    marginTop: 10,
  },
  backarrow: {
    marginInlineStart: "-100px",
    cursor: "pointer",
    marginTop: "2%",
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
  input: {
    marginLeft: "40%",
  },
  social_icon: {
    marginTop: 10,
    width: "40px",
  },
}));

export default function Editnews(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
 
  const [News_data, setNewsData] = React.useState(props.location.news_data); //title of news

  const [title, setTtitle] = React.useState(News_data.title); //title of news
  const [description, setDescription] = React.useState(
    News_data.short_description
  ); //Description of news
  const [status, setStatus] = React.useState(News_data.status);
  const [text, settext] = React.useState(News_data.detailed_description);
  const [image, setImage] = React.useState(News_data.image);
  const [file, setPhoto] = React.useState("");
  const [is_url, setis_url] = React.useState(false);
  const [final_tags, setFinalTags] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [Savepublish, setSavePublish] = React.useState(
    News_data.send_notification
  );
  // patient tags
  const [name, set_name] = React.useState([]);
  const [patients_tags, setpatients_tags] = React.useState([]);

  const [add_news, setAddNews] = React.useState(false);
  const [ref_url, setRefURL] = React.useState(
    News_data.reference.reference_url
  );
  const [refImage, set_Ref_Image] = React.useState(
    News_data.reference.reference_image
  );
  const [ref_img, setRefImage] = React.useState();

  const [facebook_url, setfacebookurl] = React.useState(
    News_data.social_media_links.facebook_link
  );
  const [instagram_url, setinstagramurl] = React.useState(
    News_data.social_media_links.instagram_link
  );
  const [linkedInurl, setyoutubeurl] = React.useState(
    News_data.social_media_links.linkd_in_link
  );
  const [twitter_url, settwitterurl] = React.useState(
    News_data.social_media_links.twitter_link
  );
  const [errorflag, setflag] = React.useState("");
  const handleback = () => {
    history.goBack();
  };
  const hanel_change_refurl = (event) => {
    setRefURL(event.target.value);
  };
  const handle_delete_ref_img = () => {
    set_Ref_Image("");
  };
  const handle_change_ref_image = (event) => {
    setRefImage(event.target.files[0]);
  };
  const handle_addsocial = () => {
    setis_url(!is_url);
  };
  const handlefacebook_url = (event) => {
    console.log(event.target.value, "value");
    setfacebookurl(event.target.value);
  };
  const handleinstagram_url = (event) => {
    console.log(event.target.value, "Instagram link");
    setinstagramurl(event.target.value);
  };
  const handlelinkedInurl = (event) => {
    console.log(event.target.value, "linked in youtube");
    setyoutubeurl(event.target.value);
  };
  const handletwitter_url = (event) => {
    settwitterurl(event.target.value);
  };
  // handle patients tags
  const handle_patiett_tag_change = (value) => {
    console.log(value, "value");
    let _patTag = [];
    value.map((x, i) => {
      _patTag.push({ name: x.name, user_id: x.user_id });
    });
    setpatients_tags(_patTag);
    console.log(_patTag, "final tags");
  };
  const handle_tag_change = (value) => {
    console.log(value, "value");
    let _tags = [];
    value.map((x, i) => {
      _tags.push({ title: x });
    });
    setFinalTags(_tags);
    console.log(_tags, "final tags");
  };
  const fileChangedHandler = (event) => {
    setPhoto(event.target.files[0]);
  };
  const handleChangeInCkEdditor = (text) => {
    console.log(text, "text");
    settext(text);
  };
  const handleSavePublishChange = (event) => {
    setSavePublish(event.target.value);
    console.log(event.target.value, "save or publish");
  };
  const get_users = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/customer/list_all_customers",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        let patients = [];
        let customer = res.customer;
        customer.map((x, i) => {
          if (x.is_patient == true) {
            patients.push(x);
          }
        });
        set_name(patients);
        setpatients_tags(News_data.patients_tags);
      }
    });
  };
  const Addnews = (event) => {
    event.preventDefault();
    setAddNews(true);
    let formData = new FormData(); //formdata object
    formData.append("title", title);
    formData.append("status", status);
    var filtered = final_tags.filter(function (el) {
      return el.title != null && el.title !== "" && el.title.trim() !== "";
    });
    formData.append("tags", JSON.stringify(filtered));
    formData.append("short_description", description);
    formData.append("detailed_description", text);
    formData.append("reference_url", ref_url);
    formData.append("save_or_publish", Savepublish);
    var Patient_filtered = patients_tags.filter(function (el) {
      return el.name != null && el.name !== "" && el.name.trim() !== "";
    });
    formData.append("patients_tags", JSON.stringify(Patient_filtered));
    if (ref_img) {
      formData.append("reference_image", ref_img);
    }
    formData.append("reference_image_path", refImage);

    if (file) {
      formData.append("image", file);
    }
    if (facebook_url) {
      formData.append("facebook_link", facebook_url);
    } else {
      formData.append("facebook_link", "");
    }
    if (twitter_url) {
      formData.append("twitter_link", twitter_url);
    } else {
      formData.append("twitter_link", "");
    }
    if (instagram_url) {
      formData.append("instagram_link", instagram_url);
    } else {
      formData.append("instagram_link", "");
    }
    if (linkedInurl) {
      formData.append("linkd_in_link", linkedInurl);
    } else {
      formData.append("linkd_in_link", "");
    }

    formData.append("order", News_data.order);
    console.log(News_data.order,"News_data.orderNews_data.order")

    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/news/edit_news/${News_data._id}`,
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        props.history.push("/postit/list-news");
        enqueueSnackbar(res.message, { variant: "success" })
      } else {
        setflag(res.message);
        setAddNews(false);
        enqueueSnackbar(res.message, { variant: "error" })
      }
    });
  };
  const get_tags = () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/tags/all_tags`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setTags(res.tags);
      } else {
        setAddNews(false);
      }
    });
  };

  const get_initial_data = async () => {
    await get_tags();
    await get_users();
  };
  useEffect(() => {
    get_initial_data();
  }, []);
  return (
    <>
      {add_news == true ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="xs">
          <ArrowBackIcon
            className={classes.backarrow}
            onClick={handleback}
          ></ArrowBackIcon>
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Edit news
            </Typography>
            <form className={classes.form} onSubmit={Addnews}>
              {errorflag && <Alert severity="error">{errorflag}</Alert>}
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={title}
                    onChange={(e) => setTtitle(e.target.value)}
                    fullWidth
                    label="Title"
                    name="title"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl component="fieldset" style={{ marginTop: 20 }}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="primary"
                          checked={status}
                          onClick={() => setStatus(!status)}
                        />
                      }
                      label="Status"
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <TextField
                variant="outlined"
                margin="normal"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={2}
                rowsMax={4}
                fullWidth
                label="Description"
                name="description"
              />
              <TextField
                variant="outlined"
                margin="normal"
                value={ref_url}
                onChange={hanel_change_refurl}
                fullWidth
                label="Reference url"
                name="Reference_url"
              />
              <CKEditor content={text} setContent={handleChangeInCkEdditor} />

              <Autocomplete
                multiple
                id="tags-filled"
                options={name}
                value={patients_tags}
                getOptionLabel={(name) => name.name}
                getOptionSelected={(option, value) =>
                  option.user_id === value.user_id
                }
                onChange={(event, value) => handle_patiett_tag_change(value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <>
                      <Chip
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    </>
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    multiline
                    style={{ marginTop: 20 }}
                    rows={1}
                    rowsMax={4}
                    label="Patients"
                    placeholder="Enter new Patient"
                  />
                )}
              />
              {ref_img ? (
                <img
                  src={URL.createObjectURL(ref_img)}
                  className={classes.blog_img}
                ></img>
              ) : refImage ? (
                <img
                  src={image_url + refImage}
                  className={classes.blog_img}
                ></img>
              ) : null}
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Button
                    fullWidth
                    variant="outlined"
                    component="label"
                    className={classes.uplaod}
                    startIcon={<CloudUploadIcon />}
                    color="primary"
                  >
                    <input
                      type="file"
                      accept="image/x-png,image/jpeg"
                      hidden
                      onChange={handle_change_ref_image}
                    ></input>
                    Upload reference image (300X100)
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  {/* <Button
                    fullWidth
                    variant="outlined"
                    component="label"
                    className={classes.uplaod}
                    onClick={handle_delete_ref_img}
                    color="primary"
                  >
                    Delete image
                  </Button> */}
                </Grid>
              </Grid>

              {file ? (
                <img
                  src={URL.createObjectURL(file)}
                  className={classes.blog_img}
                ></img>
              ) : image ? (
                <img src={image_url + image} className={classes.blog_img}></img>
              ) : null}
              <Button
                fullWidth
                variant="outlined"
                component="label"
                className={classes.uplaod}
                startIcon={<CloudUploadIcon />}
                color="primary"
              >
                <input
                  type="file"
                  accept="image/x-png,image/jpeg"
                  hidden
                  onChange={fileChangedHandler}
                ></input>
                Upload image (600X500)
              </Button>
              <Button
                fullWidth
                onClick={handle_addsocial}
                variant="outlined"
                style={{ marginTop: 10 }}
                color="primary"
              >
                Add Social media links here
              </Button>
              {is_url ? (
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <img
                      className={classes.social_icon}
                      src="https://img.icons8.com/color/48/000000/facebook-circled--v2.png"
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      size="small"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Facebook Url"
                      name="title"
                      onChange={handlefacebook_url}
                      value={facebook_url}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <img
                      className={classes.social_icon}
                      style={{ width: 35, borderRadius: 50, marginTop: 15 }}
                      src="https://cdn2.iconfinder.com/data/icons/social-media-applications/64/social_media_applications_3-instagram-256.png"
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      size="small"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Instagram Url"
                      name="title"
                      onChange={handleinstagram_url}
                      value={instagram_url}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <img
                      className={classes.social_icon}
                      src="https://img.icons8.com/color/48/000000/twitter-circled--v4.png"
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      size="small"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Twitter Url"
                      name="title"
                      onChange={handletwitter_url}
                      value={twitter_url}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <img
                      className={classes.social_icon}
                      src="https://cdn2.iconfinder.com/data/icons/social-media-2102/100/social_media_circled_network-03-512.png"
                      //src="https://img.icons8.com/color/48/000000/youtube-play.png"
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      size="small"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Linkedin Url"
                      name="title"
                      onChange={handlelinkedInurl}
                      value={linkedInurl}
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Save or Publish
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={Savepublish}
                      onChange={handleSavePublishChange}
                      label="Save or Publish"
                    >
                      <MenuItem value={false}>Save</MenuItem>
                      <MenuItem value={true}>Publish</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Edit News
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
