import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import TablePagination from "@material-ui/core/TablePagination";
import { invokeApi } from "../../../../bl_libs/ApiCalling";
import { image_url } from "../../../../bl_libs/ApiCalling";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import default_img from "../../../../assets/images/default_img.jpg";
import { useSnackbar } from "notistack";
var moment = require("moment-timezone");

//-------dialog-transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  hed: {
    fontWeight: "bold",
  },
  clmwdth: {
    width: "10px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnadd: {
    float: "right",
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  mnu: {
    cursor: "pointer",
  },
});

function Listusers(props) {
  const classes = useStyles();
  const tableRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState([]);
  const [selectedrow, setrowdata] = React.useState([]);
  const [isuser, setusers] = React.useState(false);
  const [isdata, setisdata] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [totalPage, settotalpage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [search, setSearch] = React.useState("");
  const [filterUser, setFilterUser] = useState([])
  const [btnCheck, setBtnCheck] = useState(false)
  const [columns, setColumns] = React.useState([
    {
      title: <span className={classes.hed}>#</span>,
      width: 150,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={classes.hed}> Name</span>,
      field: "name",
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Profile image</span>,
      render: (rowData) => (
        <img
          src={
            rowData.profile_image == ""
              ? default_img
              : image_url + rowData.profile_image
          }
          style={{ width: 50, borderRadius: 50 }}
        ></img>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Email</span>,
      field: "user_id.email",
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Date of birth</span>,
      field: "DOB",
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Location</span>,
      field: "location",
      sorting: false,
    },

    {
      title: <span className={classes.hed}> Status</span>,
      render: (rowData) => (
        <div style={{ width: 100 }}>
          {rowData.status === "true" ? (
            <>
              <Radio
                style={{ float: "left" }}
                checked={rowData.status}
                color="primary"
                name="Radio"
              />
              <p style={{ paddingTop: "10px" }}>Active</p>
            </>
          ) : (
            <>
              <Radio
                style={{ float: "left" }}
                checked={true}
                disabled
                color="primary"
                name="Radio"
              />
              <p style={{ paddingTop: "10px" }}>In Active</p>
            </>
          )}
        </div>
      ),
      sorting: false,
    },
  ]);

  const hanldeSearchChange = (e) => {
    setSearch(e.target.value);
    setData([]);
    setisdata(false);
    console.log(e.target.value, "this is search");
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    const data = {
      data: e.target.value,
    };
    console.log(page, "pageno");
    const params = {
      limit: 15,
      page: page,
    };
    let requestObj = {
      path: `/api/customer/search`,
      method: "POST",
      headers: header,
      postData: data,
      queryParams: params,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        console.log(res.customer.customer.length, "row per pages");
        settotalpage(res.customer.total_pages);
        setisdata(true);
        setData(res.customer.customer);
      }
    });
  };
  const Adduser = () => {
    props.history.push("/postit/adduser");
  };
  const handleClickOpen = (rowdata) => {
    setrowdata(rowdata);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setData([]);
    setisdata(false);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    tableRef.current.dataManager.changePageSize(parseInt(event.target.value));
    setData([]);
    setisdata(false);
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const deleteuser = () => {
    setOpen(false);
    setData([]);
    setisdata(false);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    //console.log(Data);
    let requestObj = {
      path: `/api/customer/delete_customer/${selectedrow._id}`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        hanldeSearchChange({ target: { value: search } });
        enqueueSnackbar(res.message, { variant: "success" })
      } else {
        enqueueSnackbar(res.message, { variant: "success" })
      }
    });
  };
  const get_users = async (_page) => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    console.log(rowsPerPage, "row per page");
    let requestObj = {
      path: "/api/customer/list_customers",
      method: "GET",
      queryParams: { page: _page, limit: rowsPerPage },
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setisdata(true);
        const newUser = res.customer.Customers.filter(val => val.is_patient == false)
        console.log(newUser, "jaksdhkjasdhakjshdaskjdhaksjdhaksjdh")
        setFilterUser(newUser)
        setData(res.customer.Customers);
        settotalpage(res.customer.total_pages);
        setusers(true);
      }
    });
  };

  const get_filtered_users = async (value, _page) => {
    if (value == "new") {
      setBtnCheck(false)
    }
    else {
      setBtnCheck(true)
    }
    setusers(false)
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    const data = {
      type: value,
    };
    let requestObj = {
      path: "/api/customer/list_customers_v1",
      method: "POST",
      queryParams: { page: _page, limit: rowsPerPage },
      headers: header,
      postData: data,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setisdata(true);
        const newUser = res.customer.Customers.filter(val => val.is_patient == false)
        setFilterUser(newUser)
        setData(res.customer.Customers);
        settotalpage(res.customer.total_pages);
        setusers(true);
      }
      else {
        setusers(true)
      }
    });
  };


  const getAllUsers = () => {
    setBtnCheck(!btnCheck)
    setFilterUser(data)
  }
  const getNewUsers = () => {
    setBtnCheck(!btnCheck)
    const newUser = data?.filter(val => val.is_patient == false)
    setFilterUser(newUser)
  }

  useEffect(() => {
    console.log(search, "useEffect Search");
    if (search == "") {
      // get_users(page);
      get_filtered_users("new", page);
    } else {
      hanldeSearchChange({ target: { value: search } });
    }
  }, [rowsPerPage, page]);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>

      <MuiThemeProvider>
        <Button
          onClick={() => get_filtered_users("new")}
          variant={btnCheck === false ? "contained" : "outlined"}
          color="primary"
          style={{ marginRight: "10px", fontSize: "12px" }}
        >
          New Users
        </Button>

        <Button
          onClick={() => get_filtered_users("all")}
          variant={btnCheck !== false ? "contained" : "outlined"}
          color="primary"
          style={{ fontSize: "12px" }}
        >
          All Users
        </Button>

        <Button
          onClick={Adduser}
          className={classes.btnadd}
          variant="contained"
          color="primary"
        >
          Add user
        </Button>

        {isuser == false ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width:"100%", height:"50vh" }}>
            <CircularProgress />
          </div>
        ) : (
          <MaterialTable
            tableRef={tableRef}
            onSearchChange={(e) => console.log("search changed: " + e)}
            style={{ marginTop: "50px" }}
            title="Users"
            icons={tableIcons}
            columns={columns.map((c) => ({ ...c, tableData: undefined }))}
            components={{
              Pagination: (props) => (
                <TablePagination
                  component="div"
                  count={totalPage}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              ),
              Toolbar: (props) => (
                <div>
                  <Box display="flex" p={1}>
                    <Box p={1} flexGrow={1}>
                      <h3>Users</h3>
                    </Box>
                    <Box p={1}>
                      <TextField
                        id="input-with-icon-textfield"
                        value={search}
                        focused
                        autoFocus={true}
                        onChange={hanldeSearchChange}
                        //label="Search"
                        placeholder="Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ float: "right" }}
                            >
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                </div>
              ),
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div style={{ padding: 20 }}>
                    {isdata == false ? (
                      <CircularProgress />
                    ) : (
                      <h5>No data found to display</h5>
                    )}
                  </div>
                ),
              },
            }}
            data={data}
            actions={[
              {
                icon: () => <DeleteIcon />,
                tooltip: "Delete user",
                onClick: (event, rowData) => handleClickOpen(rowData),
              },
              {
                icon: () => <EditIcon />,
                tooltip: "Edit user",
                onClick: (event, rowData) =>
                  props.history.push({
                    pathname: "/postit/edituser",
                    user: rowData,
                  }),
              },
            ]}
            options={{
              actionsColumnIndex: -1,
              search: false,
              pageSize: rowsPerPage,
              //pageSizeOptions: [15, 25, 50, 100],
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: "#DAD651",
                color: "white",
                fontWeight: "bold",
              },
            }}
          />
        )}
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete ${selectedrow.name}`}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Disagree
            </Button>
            <Button onClick={deleteuser} color="primary">
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>

    </>
  );
}
export default withRouter(Listusers);
