import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { createMuiTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { invokeApi } from "../../bl_libs/ApiCalling";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#601124",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#601124",
    },

    width: "100%",
    marginTop: "20px",
  },
  backarrow: {
    marginInlineStart: "-580px",
    cursor: "pointer",
    color: "primary",
  },
  btn: {
    "&.MuiButton-containedPrimary": {
      backgroundColor: "#601124",
      width: "100%",
      height: "50px",
      marginTop: "20px",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#d8d433",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: "50px",
    color: "white",
  },
}));

export default function UpdatePassword(props) {
  const classes = useStyles();
  const [c_password, setcurrentpassword] = useState("");
  const [n_password, setnewpassword] = useState("");
  const [old_password, setoldpassword] = useState("");
  const [is_passwordUpdate, setupdatepassword] = useState("");
  const [email, setemail] = useState("");

  const [errorflag, setflag] = useState("");

  function handlcurrentpassword(event) {
    setcurrentpassword(event.target.value);
  }
  const handleoldpassword = (event) => {
    setoldpassword(event.target.value);
  };
  function handleemail(e) {
    setemail(e.target.value);
  }
  const handleback = () => {
    props.history.goBack();
  };
  function handlnewpassword(event) {
    setnewpassword(event.target.value);
  }
  function handleconfirmpassword(event) {
    setcurrentpassword(event.target.value);
  }

  function login(event) {
    setupdatepassword("");
    setflag("");
    event.preventDefault();
    console.log("btn clicked")
    const data = {
      password: old_password,
      new_password: n_password,
      confirm_password: c_password,
    };
    let requestObj = {
      path: "/api/admin/change_password",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    console.log(requestObj,"ashgsajgdhjasdh")
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE UPDATE PASS");
      if (res.code == 200) {
        props.history.push("/postit/dashboard");
        setupdatepassword("password update successfully");
        console.log(res.message, "password----")
      } else {
        setflag(res.message);
        console.log(res.message, "password----")
      }
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <ArrowBackIcon
          className={classes.backarrow}
          onClick={handleback}
        ></ArrowBackIcon>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Update password
        </Typography>
        <form className={classes.form} onSubmit={login} noValidate>
          {errorflag && <Alert severity="error">{errorflag}</Alert>}
          {is_passwordUpdate && (
            <Alert severity="success">{is_passwordUpdate}</Alert>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            type="password"
            required
            fullWidth
            id="old_password"
            value={old_password}
            onChange={handleoldpassword}
            label="Current password"
            name="old_password"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            type="password"
            required
            fullWidth
            id="n_password"
            value={n_password}
            onChange={handlnewpassword}
            label="New password"
          />
          <TextField
            variant="outlined"
            margin="normal"
            type="password"
            required
            fullWidth
            id="username"
            value={c_password}
            onChange={handleconfirmpassword}
            label="Confirm new password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Update password
          </Button>
        </form>
      </div>
    </Container>
  );
}
