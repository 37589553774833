import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { invokeApi } from "../../../../bl_libs/ApiCalling";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
var moment = require("moment-timezone");

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#d8d433",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
}));

export default function Adduser(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
  const [email, setemail] = useState("");
  const [name, set_name] = useState("");
  const [location, set_location] = useState("");
  const [password, setpassword] = useState("");
  const [errorflag, setflag] = useState("");
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [bio, setBio] = React.useState("");
  const [cover_img_1, setCoverImage1] = React.useState("");
  const [cover_img_2, setCoverImage2] = React.useState("");
  const [cover_img_3, setCoverImage3] = React.useState("");
  const [cover_img_4, setCoverImage4] = React.useState("");
  const [phoneNumber, setphoneNumber] = React.useState("");
  const [idNumber, setidNumber] = React.useState("");
  const [status, setStatus] = React.useState(false);
  const [is_patient, setPtaient] = React.useState(false);
  const [clinic, setClinic] = useState([]);
  const [selected_clinic, setSelectedClinic] = useState("");
  const [selectedDate, setSelectedDate] = React.useState("12/12/2002");
  const [error, set_error] = React.useState(false);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [iswaiting, setiswaiting] = useState(false);
  const handleemail = (event) => {
    setemail(event.target.value);
  };
  const handlename = (event) => {
    const regex = /^[a-z A-Z]+$/;
    set_name(event.target.value);
    if (regex.test(event.target.value)) {
      set_error(false);
      setflag("");
    } else {
      set_error(true);
    }
  };

  const handlepassword = (event) => {
    setpassword(event.target.value);
  };

  const handleaddress = (event) => {
    set_location(event.target.value);
  };
  const fileChangedHandler = (e) => {
    if (e.target.files.length) {
      setimage(e.target.files[0]);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleCoverImage1 = (e) => {
    if (e.target.files.length) {
      setCoverImage1(e.target.files[0]);
    }
  };
  const handleCoverImage2 = (e) => {
    if (e.target.files.length) {
      setCoverImage2(e.target.files[0]);
    }
  };
  const handleCoverImage3 = (e) => {
    if (e.target.files.length) {
      setCoverImage3(e.target.files[0]);
    }
  };
  const handleCoverImage4 = (e) => {
    if (e.target.files.length) {
      setCoverImage4(e.target.files[0]);
    }
  };
  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = (event) => {
    event.preventDefault();
    if (error) {
      setflag("Name should contain only alphabets");
      return;
    }
    setiswaiting(true);
    let formData = new FormData(); //formdata object
    console.log(moment(selectedDate).format("DD/MM/YYYY"));
    formData.append("name", name);
    formData.append("location", location);
    formData.append("email", email);
    formData.append("bio", bio);
    formData.append("phoneNumber", phoneNumber);
    formData.append("idNumber", idNumber);
    formData.append("DOB", moment(selectedDate).format("DD/MM/YYYY"));
    formData.append("password", password);
    if (Image) {
      formData.append("profile_image", Image);
    }
    formData.append("date_of_birth", selectedDate);
    formData.append("status", status);
    formData.append("is_patient", is_patient);
    is_patient === true
      ? formData.append("clinic_id", selected_clinic._id)
      : formData.append("clinic_id", "");

    console.log(cover_img_1);
    if (cover_img_1 !== "") {
      formData.append("cover_image1", cover_img_1);
    }
    if (cover_img_2 !== "") {
      formData.append("cover_image2", cover_img_2);
    }
    if (cover_img_3 !== "") {
      formData.append("cover_image3", cover_img_3);
    }
    if (cover_img_4 !== "") {
      formData.append("cover_image4", cover_img_4);
    }
    console.log(is_patient,"aaaaaaaaaaaaaaaa")
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/customer/add_customer",
      method: "POST",
      postData: formData,
      headers: header,
    };
    if (is_patient === true && selected_clinic === "") {
      setflag("please select clinic");
    } else {
      invokeApi(requestObj).then((res) => {
        setiswaiting(true);
        console.log(res, "RESPONSE");
        if (res.code == 200) {
          props.history.push("/postit/users");
          enqueueSnackbar(res.message, { variant: "success" })
        } else {
          setiswaiting(false);
          setflag(res.message);
          enqueueSnackbar(res.message, { variant: "error" })
        }
      });
    }
  };
  const get_clinins = () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/clinic/all_clinic",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      setClinic(res.clinic);
      console.log(res, "Response");
    });
  };
  useEffect(() => {
    get_clinins();
  }, []);
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography
            className={classes.txt}
            component="h1"
            variant="h5"
          ></Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <input
                accept="image/x-png,image/jpeg"
                hidden
                id="contained-button-file"
                multiple
                type="file"
                onChange={fileChangedHandler}
              />
              <label htmlFor="contained-button-file">
                <Avatar alt="Remy Sharp" className={classes.avtr} src={file}>
                  <AddAPhotoIcon />
                </Avatar>
                <span style={{ marginLeft: 8 }}>200 X 200</span>
              </label>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  error={error}
                  fullWidth
                  id="name"
                  value={name}
                  helperText="name should contain only alphabets"
                  onChange={handlename}
                  label="Name"
                  name="name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date of birth"
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  onChange={handleemail}
                  label="Email"
                  name="email"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="location"
                  value={location}
                  onChange={handleaddress}
                  label="Location"
                  name="location"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  rows={2}
                  fullWidth
                  onChange={(e) => setphoneNumber(e.target.value)}
                  name="Phone no"
                  label="Phone no"
                  type="text"
                  id="standard-multiline-flexible"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  rows={2}
                  fullWidth
                  onChange={(e) => setidNumber(e.target.value)}
                  name="User ID"
                  label="User ID"
                  type="text"
                  id="standard-multiline-flexible"
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  rowsMax={3}
                  multiline
                  rows={2}
                  fullWidth
                  onChange={(e) => setBio(e.target.value)}
                  name="Bio"
                  label="Bio"
                  type="text"
                  id="standard-multiline-flexible"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl component="fieldset" style={{ marginTop: 23 }}>
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        color="primary"
                        checked={is_patient}
                        onClick={() => setPtaient(!is_patient)}
                      />
                    }
                    label="Patient"
                    labelPlacement="start"
                  />
                </FormControl>
              </Grid>
              {is_patient && (
                <Grid item xs={12}>
                  <Autocomplete
                    id="patients"
                    disableClearable
                    value={selected_clinic}
                    onChange={(event, value) => {
                      setSelectedClinic(value);
                    }}
                    getOptionLabel={(option) => option.clinic_name}
                    options={clinic}
                    className={classes.patient}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Clinics"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  onChange={handlepassword}
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>

              <Grid item xs={4}>
                <FormControl component="fieldset" style={{ marginTop: 23 }}>
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        color="primary"
                        checked={status}
                        onClick={() => setStatus(!status)}
                      />
                    }
                    label="Status"
                    labelPlacement="start"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                {cover_img_1 ? (
                  <img
                    src={URL.createObjectURL(cover_img_1)}
                    className={classes.blog_img}
                  ></img>
                ) : null}
                <Button
                  fullWidth
                  variant="outlined"
                  component="label"
                  className={classes.uplaod}
                  startIcon={<CloudUploadIcon />}
                  color="primary"
                >
                  <input
                    type="file"
                    accept="image/x-png,image/jpeg"
                    hidden
                    onChange={handleCoverImage1}
                  ></input>
                  (1) Upload Cover Image (400 X 400)
                </Button>
              </Grid>
              <Grid item xs={12}>
                {cover_img_2 ? (
                  <img
                    src={URL.createObjectURL(cover_img_2)}
                    className={classes.blog_img}
                  ></img>
                ) : null}
                <Button
                  fullWidth
                  variant="outlined"
                  component="label"
                  className={classes.uplaod}
                  startIcon={<CloudUploadIcon />}
                  color="primary"
                >
                  <input
                    type="file"
                    accept="image/x-png,image/jpeg"
                    hidden
                    onChange={handleCoverImage2}
                  ></input>
                  (2) Upload Cover Image (400 X 400)
                </Button>
              </Grid>
              <Grid item xs={12}>
                {cover_img_3 ? (
                  <img
                    src={URL.createObjectURL(cover_img_3)}
                    className={classes.blog_img}
                  ></img>
                ) : null}
                <Button
                  fullWidth
                  variant="outlined"
                  component="label"
                  className={classes.uplaod}
                  startIcon={<CloudUploadIcon />}
                  color="primary"
                >
                  <input
                    type="file"
                    accept="image/x-png,image/jpeg"
                    hidden
                    onChange={handleCoverImage3}
                  ></input>
                  (3) Upload Cover Image (400 X 400)
                </Button>
              </Grid>
              {cover_img_4 ? (
                <img
                  src={URL.createObjectURL(cover_img_4)}
                  className={classes.blog_img}
                ></img>
              ) : null}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  component="label"
                  className={classes.uplaod}
                  startIcon={<CloudUploadIcon />}
                  color="primary"
                >
                  <input
                    type="file"
                    hidden
                    onChange={handleCoverImage4}
                  ></input>
                  (4) Upload Cover Image (400 X 400)
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add user
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
