import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { invokeApi } from "../../../bl_libs/ApiCalling";
import { Route, Router, useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from "@material-ui/pickers";
import { useSnackbar } from "notistack";
var moment = require("moment-timezone");

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#d8d433",
  },
  form: {
    width: "100%",
    marginTop: 20, // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
  },
  alert: {
    width: "100%",
    margin: 10,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  patient: {
    marginTop: -15,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, customer_id, theme) {
  return {
    fontWeight:
      customer_id.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function Addappointment(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar()
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [name, set_name] = useState([]);
  const [date, setDate] = useState(new moment().format("YYYY-MM-DDTHH:mm"));
  const [errorflag, setflag] = useState("");
  const [customer, setCustomer] = React.useState({});
  const [status, setStatus] = React.useState(true);
  const [iswaiting, setiswaiting] = useState(false);

  // functions
  const handleDateChange = (date) => {
    setDate(new moment(date).format("YYYY-MM-DDTHH:mm"));
  };

  const handledescritpion = (event) => {
    setDescription(event.target.value);
  };
  const handlename = (event) => {
    set_name(event.target.value);
  };

  const handleCustomer = (event) => {
    setCustomer(event.target.value);
  };
  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = (event) => {
    event.preventDefault();
    setiswaiting(true);
    let formData = new FormData(); //formdata object
    console.log(customer, "this is custmer");
    if(!customer.user_id){
      setflag('Patient is required');
      setiswaiting(false);
      return
    }
    formData.append("user_id", customer.user_id);
    formData.append("description", description);
    formData.append("appointment_date_time", date);
    formData.append("status", status);
    formData.append("title", title);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/appointment/add_appointment",
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE ADD APPOINTMENT");
      if (res.code == 200) {
        history.goBack();
        setiswaiting(false);
        enqueueSnackbar("Appointment added successfully", { variant: "success" })
      } else {
        setiswaiting(false);
        setflag(res.message);
        enqueueSnackbar(res.message, { variant: "success" })
      }
    });
  };
  const get_users = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/customer/list_all_customers",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        let patients = [];
        let customer = res.customer;
        customer.map((x, i) => {
          if (x.is_patient == true) {
            patients.push(x);
          }
        });
        set_name(patients);
      }
    });
  };
  useEffect(() => {
    get_users();
  }, []);
  let x;
  if(x = (document.getElementsByClassName("MuiPickersToolbarText-toolbarTxt").innerHTMl = "SOY")) {
    document.getElementsByClassName("MuiPickersToolbarText-toolbarTxt").innerHTMl = "AM";
  }
  return (
    <>
      {iswaiting == true ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <ArrowBackIcon
              style={{
                cursor: "pointer",
                marginLeft: "-550px",
              }}
              onClick={handleback}
            />
            <Typography component="h1" variant="h5">
              Add Appointment
            </Typography>
            {errorflag && (
              <Alert className={classes.alert} severity="error">
                {errorflag}
              </Alert>
            )}
            <form className={classes.form} onSubmit={handlesubmit}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              ></Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Autocomplete
                    id="patients"
                    disableClearable
                    value={customer}
                    onChange={(event, value) => {
                      setCustomer(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    options={name}
                    className={classes.patient}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Patients"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: -20, marginTop: -15 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Appointment"
                      format="dd/MM/yyyy hh:mm a"
                      inputVariant="outlined"
                      value={date}
                      disablePast={true}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="text"
                    required
                    value={title}
                    fullWidth
                    id="des"
                    onChange={(e) => setTitle(e.target.value)}
                    label="Title"
                    name="title"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormControl component="fieldset" style={{ marginTop: 25 }}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="primary"
                          checked={status}
                          onClick={() => setStatus(!status)}
                        />
                      }
                      label="Status"
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="text"
                    multiline
                    rowsMax={3}
                    rows={3}
                    required
                    fullWidth
                    id="des"
                    onChange={handledescritpion}
                    label="Description"
                    name="emaDescriptionil"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Add Appointment
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
