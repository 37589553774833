import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Avatar from "@material-ui/core/Avatar";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { invokeApi } from "../../../../bl_libs/ApiCalling";
var moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 600, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    height: 55,
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  uplaod: {
    marginTop: 20,
  },
  blog_img: {
    marginTop: 10,
    width: 600,
    height: 300,
  },
  formControl: {
    marginTop: 10,
  },
  backarrow: {
    marginInlineStart: "-100px",
    cursor: "pointer",
    marginTop: "2%",
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
  input: {
    marginLeft: "40%",
  },
}));

export default function AddVideo(props) {
  const classes = useStyles();
  const history = useHistory();
  const [default_user, setDefaultUsers] = React.useState([]);
  const [selected_user, setSelectedCustomer] = React.useState({});
  const [file, setPhoto] = React.useState("");
  const [video, setVideo] = React.useState("");
  const [status, setStatus] = React.useState(true);
  const [description, setDescription] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [final_tags, setFinalTags] = React.useState([]);
  const [Savepublish, setSavePublish] = React.useState(false);
  const [video_add, setAddVideo] = React.useState(false);
  const [name, set_name] = React.useState([]);
  const [patients_tags, setpatients_tags] = React.useState([]);

  const [errorflag, setflag] = React.useState("");
  const handle_tag_change = (value) => {
    console.log(value, "value");
    let _tags = [];
    value.map((x, i) => {
      _tags.push({ title: x });
    });
    setFinalTags(_tags);
    console.log(_tags, "final tags");
  };
  const handleback = () => {
    history.goBack();
  };

  const fileChangedHandler = (event) => {
    setPhoto(event.target.files[0]);
  };
  const handleSavePublishChange = (event) => {
    setSavePublish(event.target.value);
    console.log(event.target.value, "save or publish");
  };
  const AddVideo = (event) => {
    event.preventDefault();
    setAddVideo(true);
    let formData = new FormData(); //formdata object
    formData.append("user_id", selected_user._id);
    formData.append("status", status);
    console.log(final_tags, "final tags");
    var filtered = final_tags.filter(function (el) {
      return el.title != null && el.title !== "" && el.title.trim() !== "";
    });
    console.log(filtered, "final tags");
    formData.append("tags", JSON.stringify(filtered));
    formData.append("description", description);
    formData.append("video", video);
    formData.append("publish_date_time", moment());
    var Patient_filtered = patients_tags.filter(function (el) {
      return el.name != null && el.name !== "" && el.name.trim() !== "";
    });
    console.log(Patient_filtered, "pat tags");
    formData.append("patients_tags", JSON.stringify(Patient_filtered));
    formData.append("save_or_publish", Savepublish);

    //console.log("DATE", Date().toLocaleString());
    //formData.append("publish_date_time", Date().toLocaleString());
    if (file) {
      formData.append("image", file);
    }

    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/video/add_video",
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        props.history.push("/postit/videos");
      } else {
        setAddVideo(false);
        setflag(res.message);
      }
    });
  };
  const get_users = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/customer/list_all_customers",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        let patients = [];
        let customer = res.customer;
        customer.map((x, i) => {
          if (x.is_patient == true) {
            patients.push(x);
          }
        });
        set_name(patients);
      }
    });
  };
  const handle_patiett_tag_change = (value) => {
    console.log(value, "value");
    let _patTag = [];
    value.map((x, i) => {
      _patTag.push({ name: x.name, user_id: x.user_id });
    });
    setpatients_tags(_patTag);
    console.log(_patTag, "final tags");
  };
  const get_tags = () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/tags/all_tags`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setTags(res.tags);
      }
    });
  };
  const getDefaultUser = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/customer/default_customer`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        const default_user = [];
        res.customer.map((x, i) => {
          if (x.status == "true" || x.status == true) {
            default_user.push(x);
          }
        });
        console.log(default_user, "default_user");
        setDefaultUsers(default_user);
      }
    });
  };

  const get_initial_data = async () => {
    await getDefaultUser();
    await get_tags();
    await get_users();
  };
  useEffect(() => {
    get_initial_data();
  }, []);
  return (
    <>
      {video_add == true ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="xs">
          <ArrowBackIcon
            className={classes.backarrow}
            onClick={handleback}
          ></ArrowBackIcon>
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Add video
            </Typography>
            <form className={classes.form} onSubmit={AddVideo}>
              {errorflag && (
                <div style={{ padding: 10 }}>
                  <Alert severity="error">{errorflag}</Alert>
                </div>
              )}
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Autocomplete
                    id="users"
                    disableClearable
                    value={selected_user}
                    onChange={(event, value) => {
                      setSelectedCustomer(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    options={default_user}
                    className={classes.patient}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose user"
                        margin="normal"
                        variant="outlined"
                        required
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl component="fieldset" style={{ marginTop: 10 }}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="primary"
                          checked={status}
                          onClick={() => setStatus(!status)}
                        />
                      }
                      label="Status"
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {/* tags */}
              {/* <Autocomplete
                multiple
                id="tags-filled"
                options={tags.map((option) => option.title)}
                onChange={(event, value) => handle_tag_change(value)}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <>
                      {value[index].split(" ").join("") !== "" ? (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ) : null}
                    </>
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    multiline
                    style={{ marginTop: 20 }}
                    rows={1}
                    rowsMax={4}
                    label="Tags"
                    placeholder="Enter new tag"
                  />
                )}
              /> */}
              <Autocomplete
                multiple
                id="tags-filled"
                options={name}
                getOptionLabel={(name) => name.name}
                onChange={(event, value) => handle_patiett_tag_change(value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <>
                      <Chip
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    </>
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    multiline
                    style={{ marginTop: 20 }}
                    rows={1}
                    rowsMax={4}
                    label="Patients"
                    placeholder="Enter new Patient"
                  />
                )}
              />
              <TextField
                variant="outlined"
                margin="normal"
                multiline
                onChange={(e) => setDescription(e.target.value)}
                vaue={description}
                rows={2}
                rowsMax={4}
                fullWidth
                label="Description"
                name="description"
              />
              <TextField
                variant="outlined"
                margin="normal"
                value={video}
                onChange={(e) => setVideo(e.target.value)}
                fullWidth
                label="Enter Vimeo/Youtube video URL"
                name="description"
              />
              {file ? (
                <img
                  src={URL.createObjectURL(file)}
                  className={classes.blog_img}
                ></img>
              ) : null}
              <Button
                fullWidth
                variant="outlined"
                component="label"
                className={classes.uplaod}
                startIcon={<CloudUploadIcon />}
                color="primary"
              >
                <input
                  type="file"
                  accept="image/x-png,image/jpeg"
                  hidden
                  onChange={fileChangedHandler}
                ></input>
                Upload image (600X500)
              </Button>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Save or Publish
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={Savepublish}
                      fullWidth
                      onChange={handleSavePublishChange}
                      label="Save or Publish"
                    >
                      <MenuItem value={false}>Save</MenuItem>
                      <MenuItem value={true}>Publish</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Add Video
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
