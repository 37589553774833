import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CKEditor from "./editor/CKEditor";
import Alert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { invokeApi, image_url } from "../../../../bl_libs/ApiCalling";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 600, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
  },
  uplaod: {
    marginTop: 20,
  },
  blog_img: {
    marginTop: 10,
    width: 600,
    height: 300,
  },
  formControl: {
    marginTop: 10,
  },
  backarrow: {
    marginInlineStart: "-100px",
    cursor: "pointer",
    marginTop: "2%",
  },
}));

export default function Editcategory(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
  if (!props.location.Data) {
    props.location.Data = {};
  }
  const [category_data, setnames] = React.useState(props.location.Data);
  const [category_image, setImage] = React.useState(category_data.image);
  const [file, setBlogImage] = React.useState("");
  const [status, setStatus] = React.useState(category_data.status);
  const [text, settext] = React.useState(category_data.detailed_description);
  const [blog_title, setblog_title] = React.useState(category_data.title);
  const [blog_description, setblog_des] = React.useState(
    category_data.short_description
  );
  const [errorflag, setflag] = React.useState("");
  const [add_blog, set_add_blog] = React.useState(false);

  const theme = useTheme();

  const handleback = () => {
    history.goBack();
  };
  const handleblog_title = (event) => {
    setblog_title(
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
    );
    console.log(blog_title);
  };
  const handleblog_des = (event) => {
    setblog_des(
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
    );
    console.log(blog_description);
  };
  const fileChangedHandler = (event) => {
    //const file = event.target.files[0];
    console.log(file);
    // setBlogImage(URL.createObjectURL(event.target.files[0]));
    setBlogImage(event.target.files[0]);
  };

  const handleChangeInCkEdditor = (text) => {
    settext(text);
  };
  const handleEditcategory = (event) => {
    event.preventDefault();
    let formData = new FormData(); //formdata object
    formData.append("title", blog_title);
    formData.append("status", status);
    formData.append("short_description", blog_description);
    formData.append("detailed_description", text);
    if (file !== "") {
      formData.append("image", file);
    }

    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/blog_category/edit_blog_category/${category_data._id}`,
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        props.history.push("/postit/blogCategories");
        enqueueSnackbar(res.message, { variant: "success" })
      } else {
        setflag(res.message);
        enqueueSnackbar(res.message, { variant: "error" })
      }
    });
  };

  useEffect(() => {
    if (JSON.stringify(props.location.Data) == JSON.stringify({})) {
      props.history.push("/postit/blogCategories");
    }
  }, []);
  return (
    <Container component="main" maxWidth="xs">
      <ArrowBackIcon
        className={classes.backarrow}
        onClick={handleback}
      ></ArrowBackIcon>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Edit blog category
        </Typography>
        <form className={classes.form} onSubmit={handleEditcategory}>
          {errorflag && <Alert severity="error">{errorflag}</Alert>}
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Category title"
                name="title"
                onChange={handleblog_title}
                value={blog_title}
              />
            </Grid>
            <Grid xs={4}>
              <FormControl component="fieldset" style={{ marginTop: 35 }}>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={status}
                      onClick={() => setStatus(!status)}
                    />
                  }
                  label="Status"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="normal"
            multiline
            rows={2}
            rowsMax={4}
            fullWidth
            onChange={handleblog_des}
            label="Short Description"
            name="title"
            value={blog_description}
          />

          <CKEditor content={text} setContent={handleChangeInCkEdditor} />

          {file ? (
            <img
              src={URL.createObjectURL(file)}
              className={classes.blog_img}
            ></img>
          ) : category_image !== "" ? (
            <img
              src={image_url + category_image}
              className={classes.blog_img}
            ></img>
          ) : null}

          <Button
            fullWidth
            variant="outlined"
            component="label"
            className={classes.uplaod}
            startIcon={<CloudUploadIcon />}
            color="primary"
          >
            <input
              type="file"
              accept="image/x-png,image/jpeg"
              hidden
              onChange={fileChangedHandler}
            ></input>
            Upload blog category image (500X500)
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Edit blog category
          </Button>
        </form>
      </div>
    </Container>
  );
}
