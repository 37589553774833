import React, {Component} from 'react';
import not_found from "../../assets/images/404.png";


class NotFound extends Component {
    render() {
        return (
            <div>
                <img src={not_found}  className="App-logo" alt="logo" />
            </div>
        );
    }
}

export default NotFound;